import { useEffect } from "react";
import Sidenav from "components/front/companyDetails/tutorials/Sidenav";
import Header from "components/front/header/Header";
import Footer from "components/front/footer/Footer";

const Dashboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />

      <main className="min-h-full bg-white">
        <div className="mx-auto max-w-2xl px-4 lg:px-8 pt-4 pb-2 md:pt-2 md:pb-4 lg:pt-8 lg:pb-4 lg:max-w-7xl lg:px-8">
          <h1 className="pb-8 text-xl font-normal uppercase tracking-tight text-gray-900 md:text-2xl">
            Tutorials
          </h1>

          <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
            {/* Left: Navigation Links */}
            <Sidenav />

            {/* Right: Content Area */}
            <main className="flex-1 p-4 md:p-6 bg-white shadow-inner">

              <div className="p-6 bg-gray-50">
                <h4 className="text-2xl font-semibold mb-4">DASHBOARD</h4>
                <p className="text-gray-700 mb-4">
                  The dashboard is a brief summary of all the items you have in the system.
                </p>

                <h5 className="text-xl font-semibold mt-6 mb-2">MENU TAB</h5>
                <p className="text-gray-700 mb-4">
                  The Vensle Logo will take you to the home page when you click it from any page within the website.
                </p>
                <p className="text-gray-700 mb-4">
                  The <i className="fa fa-bell text-blue-500"></i> icon is for notifications when orders are placed.
                </p>
                <p className="text-gray-700 mb-4">
                  The <i className="fa fa-envelope text-blue-500"></i> icon is for ease access to your messages from anywhere in the site.
                </p>
                <p className="text-gray-700 mb-4">
                  The <i className="fa fa-user-circle-o text-gray-700"></i> icon, which can be your profile picture if you have any picture uploaded, is for a quick shortcut to the most used features which include:
                </p>
                <ul className="list-disc list-inside pl-4 mb-4 text-gray-700
                ">
                  <li><i className="fa fa-user text-gray-700"></i> <b>Profile</b> where you can update your profile.</li>
                  <li><i className="fa fa-credit-card text-gray-700"></i> <b>Go back to Website</b> takes you to the homepage.</li>
                  <li><i className="fa fa-inbox text-gray-700"></i> <b>Inbox</b> takes you to your messages.</li>
                  <li><i className="fa fa-cog text-gray-700"></i> <b>Setting</b> takes you to the settings of your password.</li>
                  <li><i className="fa fa-power-off text-gray-700"></i> <b>Logout</b> logs you out of the system.</li>
                </ul>

                <h5 className="text-xl font-semibold mt-6 mb-2">LEFT SIDE BAR</h5>
                <p className="text-gray-700 mb-4">
                  The section consists of the Dashboard Tab, Products (Collapsed) Tab, Settings (Collapsed) Tab, and Message (Collapsed) Tab. To expand the Collapsed Tabs, please click them and the list of menus under them will display. The next three sections will explain them more.
                </p>

                <h5 className="text-xl font-semibold mt-6 mb-2">MID-CONTENT</h5>
                <p className="text-gray-700 mb-4">
                  The product box shows you the total number of items you have displayed on the website. <b>Most Recent Items</b> below shows you a few of your displayed products if you have any. If you don't have any, that section will be blank. <b>View all</b> is a shortcut to view all the items you have uploaded.
                </p>
              </div>

            </main>
          </div>

        </div>
      </main>


      <Footer />
    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

import { fetchCategories } from 'actions/categoryActions';

import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import Facebook from "layouts/front/socialAuthRedirect/Facebook";
import Filter from "layouts/front/filter";
import Home from "layouts/front/home";
import SocialRedirect from "layouts/front/socialAuthentication/SocialRedirect";
import SavedItems from "layouts/front/savedItems";
import ProductDetail from "layouts/front/productDetail";
import GroceryDetail from "layouts/front/groceryDetail";
import Cart from "layouts/front/cart";
import PaymentSuccess from "layouts/front/paymentSuccess";
import SettingsTutorial from "layouts/front/howTo/settings";
import DashboardTutorial from "layouts/front/howTo/dashboard";
import HowTo from "layouts/front/howTo";
import Faq from "layouts/front/faq";
import PrivacyPolicy from "layouts/front/privacyPolicy";
import TermsOfUse from "layouts/front/termsOfUse";
import ProductListing from "layouts/front/productListing";
import PaymentCancel from "layouts/front/paymentCancel";
import OrderSummary from "layouts/front/orderSummary";
import Checkout from "layouts/front/checkout";
import UserProfile from "layouts/front/userProfile";
import NotFound from "layouts/front/notFound";
import Toaster from "components/front/toaster/Toaster";

import Chat from "layouts/front/sandbox"

const App = () => {
  const dispatch = useDispatch();

  const [countryCode, setCountryCode] = useState(null);
  const [countryFlagUrl, setCountryFlagUrl] = useState(null);

  /*TODO: put in LocationInitializer component*/
  useEffect(() => {
    const fetchCountryInfo = async () => {
      try {
        {/*TODO: put token in env*/ }
        //const ipinfoResponse = await axios.get('https://ipinfo.io/json?token=09389931bcf565');
        const ipinfoResponse = {
          data: {
            "ip": "146.70.104.25",
            "city": "London",
            "region": "England",
            "country": "GB",
            "loc": "51.5130,-0.0800",
            "org": "AS9009 M247 Europe SRL",
            "postal": "EC3N",
            "timezone": "Europe/London"
          }
        }
        const storedCountryCode = ipinfoResponse.data.country;
        //const storedCountryCode = "NG";

        setCountryCode(storedCountryCode);

        const countriesNowResponse = await axios.get(
          `https://countriesnow.space/api/v0.1/countries/flag/images/q?iso2=${storedCountryCode}`
        );

        const flagUrl = countriesNowResponse.data.data.flag;
        localStorage.setItem("countryCode", storedCountryCode);
        localStorage.setItem("countryFlag", flagUrl);

        setCountryFlagUrl(flagUrl);
      } catch (error) {
        console.error("Error fetching country info:", error);
      }
    };
    fetchCountryInfo();
  }, []);


  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  return (
    <>
      <Toaster />
      <Routes>
        <Route path="chat" element={<Chat />} />


        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="social/facebook/callback/*" element={<Facebook />} />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="rtl/*" element={<RtlLayout />} />
        <Route path="filter/*" element={<Filter />} />
        <Route path="social-auth-redirect/*" element={<SocialRedirect />} />
        <Route path="/product-detail/:productId" element={<ProductDetail />} />
        <Route path="grocery-detail/*" element={<GroceryDetail />} />
        <Route path="saved-items/*" element={<SavedItems />} />
        <Route path="user-profile/:userId/products" element={<UserProfile />} />
        <Route path="order-summary/*" element={<OrderSummary />} />
        <Route path="checkout/*" element={<Checkout />} />
        <Route path="/payment/success" element={<PaymentSuccess />} />
        <Route path="/payment/cancel" element={<PaymentCancel />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/how-to/more-page" element={<HowTo />} />
        <Route path="/how-to/sellers-profile" element={<HowTo />} />
        <Route path="/how-to/dashboard" element={<DashboardTutorial />} />
        <Route path="/how-to/products" element={<HowTo />} />
        <Route path="/how-to/settings" element={<SettingsTutorial />} />
        <Route path="/how-to/message" element={<HowTo />} />
        <Route path="/how-to" element={<HowTo />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/product-listing" element={<ProductListing />} />
        <Route path="cart/*" element={<Cart />} />
        <Route path="/" element={<Home />} />

        <Route path="/*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;

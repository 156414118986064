import axios from "axios";

// Action Types
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Action Creators
export const setCategories = (categories) => ({
  type: SET_CATEGORIES,
  payload: categories,
});

export const fetchCategories = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.get(`${API_BASE_URL}/categories`);
    dispatch(setCategories(response.data.categories));
  } catch (error) {
    console.error("Error fetching categories:", error);
    dispatch(setError("Error fetching categories"));
  }
};

export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  payload: isLoading,
});

export const setError = (error) => ({
  type: SET_ERROR,
  payload: error,
});
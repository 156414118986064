import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/20/solid";

import { SET_MESSAGE } from "actions/types";

import ButtonLoading from "components/Loading/ButtonLoading";

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export default function Message({ open, setOpen, productId, receiverId }) {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.auth?.user?.token);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [contentError, setContentError] = useState("");
  const [messageContent, setMessageContent] = useState("");

  const handleFillMessage = (message) => {
    setMessageContent(message)
  }

  const handleSubmitMessage = async (e) => {
    e.preventDefault();
    setContentError("");

    if (messageContent) {
      try {
        setLoading(true);
        const data = {
          content: messageContent,
          product_id: productId,
          receiver_id: receiverId
        }
        const response = await axios.post(`${apiBaseURL}/messages`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });

        dispatch({
          type: SET_MESSAGE,
          payload: { type: "success", message: "Message sent" },
        });
        setOpen(false);
        setMessageContent("");
      } catch (error) {
        setErrorMessage(`Failed to send message Please try again.`);
        console.error('Error sending message:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setContentError("Please enter a message")
    }


  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-xl md:px-4 lg:max-w-2xl">
                <div className="relative rounded-lg flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-7 w-7 rounded-full p-1 hover:bg-gray-200 transition-all ease-in-out duration-300" aria-hidden="true" />
                  </button>

                  <div className="w-full">
                    <p className="text-xl text-gray-900">Send Message</p>
                    {errorMessage && <p className="text-red-500">{errorMessage}</p>}

                    <form onSubmit={(e) => handleSubmitMessage(e)}>
                      <div className="mt-2">
                        <div className="mt-2.5">
                          <div className="flex flex-wrap gap-2 text-xs mb-3">
                            <span onClick={() => handleFillMessage("How is it")} className="border border-gray-400 rounded-full hover:bg-gray-300 cursor-pointer transition duration-300 px-3 py-1
					">How is it
                            </span>
                            <span onClick={() => handleFillMessage("I like this")} className="border border-gray-400 rounded-full hover:bg-gray-300 cursor-pointer transition duration-300 px-3 py-1">
                              I like this
                            </span>
                            <span onClick={() => handleFillMessage("How are you doing")} className="border border-gray-400 rounded-full hover:bg-gray-300 cursor-pointer transition duration-300 px-3 py-1">How are you doing
                            </span>
                            <span onClick={() => handleFillMessage("Do you still have this product")} className="border border-gray-400 rounded-full hover:bg-gray-300 cursor-pointer transition duration-300 px-3 py-1">Do you still have this product
                            </span>
                          </div>
                          <textarea
                            name="message"
                            id="message"
                            rows={4}
                            value={messageContent}
                            onChange={(e) => setMessageContent(e.target.value)}
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                          />

                          <p className="text-red-500 text-[13px]">{contentError}</p>

                        </div>
                      </div>

                      <div className="mt-4">
                        <button
                          type="submit"
                          className="flex justify-center items-center uppercase block w-full rounded-md bg-primaryColor px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryColor"
                        >
                          {loading && <ButtonLoading customClassName="mr-2" />} Submit
                        </button>
                      </div>
                    </form>

                    <div></div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

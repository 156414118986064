import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidenav = () => {
    const location = useLocation();

    return (
        <nav className="w-full md:w-64 bg-white shadow-lg border-b md:border-r border-gray-200 p-4 md:p-6">
            <ul className="space-y-4">
                <li>
                    <Link
                        to="/how-to"
                        className={`text-gray-700 hover:text-blue-500 transition duration-300 ease-in-out ${location.pathname === '/how-to' ? 'font-bold text-blue-600' : ''}`}
                    >
                        HOME PAGE
                    </Link>
                </li>
                <li>
                    <a
                        href="#view-more"
                        className={`text-gray-700 hover:text-blue-500 transition duration-300 ease-in-out ${location.hash === '#view-more' ? 'font-bold text-blue-600' : ''}`}
                    >
                        VIEW MORE PAGE
                    </a>
                </li>
                <li>
                    <a
                        href="#profile"
                        className={`text-gray-700 hover:text-blue-500 transition duration-300 ease-in-out ${location.hash === '#profile' ? 'font-bold text-blue-600' : ''}`}
                    >
                        SELLER'S PROFILE
                    </a>
                </li>
                <li>
                    <Link
                        to="/how-to/dashboard"
                        className={`text-gray-700 hover:text-blue-500 transition duration-300 ease-in-out ${location.pathname === '/how-to/dashboard' ? 'font-bold text-blue-600' : ''}`}
                    >
                        DASHBOARD
                    </Link>
                </li>
                <li>
                    <a
                        href="#products"
                        className={`text-gray-700 hover:text-blue-500 transition duration-300 ease-in-out ${location.hash === '#products' ? 'font-bold text-blue-600' : ''}`}
                    >
                        PRODUCTS
                    </a>
                </li>
                <li>
                    <Link
                        to="/how-to/settings"
                        className={`text-gray-700 hover:text-blue-500 transition duration-300 ease-in-out ${location.pathname === '/how-to/settings' ? 'font-bold text-blue-600' : ''}`}
                    >
                        SETTINGS
                    </Link>
                </li>
                <li>
                    <a
                        href="#message"
                        className={`text-gray-700 hover:text-blue-500 transition duration-300 ease-in-out ${location.hash === '#message' ? 'font-bold text-blue-600' : ''}`}
                    >
                        MESSAGE
                    </a>
                </li>
            </ul>
        </nav>
    );
}

export default Sidenav;

import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  FunnelIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  MapPinIcon,
  Squares2X2Icon,
  ListBulletIcon,
} from "@heroicons/react/20/solid";

import Product from "components/front/product/Product";
import Grocery from "components/front/product/Grocery";
import CategorySubcategoryFilters from "./CategorySubcategoryFilters";
import Subcategories from "./Subcategories";
import FilterTag from './FilterTag';

import dealAdImage from "assets/img/front/product-filter-deals/1.jpg"
import categoryImage from "assets/img/front/filter-header/category_garden.png"
import subcategoryImage from "assets/img/front/filter-header/subcategory_garden.png"

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

const Products = () => {
  const { categories, loading: categoriesLoading } = useSelector(state => state.category);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  {/*TODO: put in useEffect*/ }

  const discount = queryParams.get("discount") || "";

  const initialDistance = queryParams.get("distance") || "";

  // const userLocation = useSelector((state) => state.location);

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  // State for filter form inputs
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [condition, setCondition] = useState("");
  const [category_id, setCategory_id] = useState("");
  const [subcategory_id, setSubcategory_id] = useState("");
  const [displayName, setDisplayName] = useState('');

  const [searchTerm, setSearchTerm] = useState("");
  const [userLocation, setUserLocation] = useState(null);

  const product = {
    breadcrumbs: [
      { id: 1, name: "Home", href: "/" },
      { id: 2, name: "Search", href: "#" },
      { id: 2, name: searchTerm, href: "#" },
    ],
  };

  const [type, setType] = useState("");
  const [sort, setSort] = useState("");
  const [listView, setListView] = useState("grid");
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [loading, setLoading] = useState([]);
  const [subcategories, setSubcategories] = useState('');
  const [activeCategory, setActiveCategory] = useState('');
  const [activeSubcategories, setActiveSubcategories] = useState([]);
  const [isLeftVisible, setLeftVisible] = useState(true);
  const [categoryFiltersLoading, setCategoryFiltersLoading] = useState(false);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [subcategoryFiltersLoading, setSubcategoryFiltersLoading] = useState(false);
  const [subcategoryFilters, setSubcategoryFilters] = useState([]);
  const [viewingSubcategories, setViewingSubcategories] = useState(false);
  const [navTypeViewing, setNavTypeViewing] = useState('');


  const [distance, setDistance] = useState(initialDistance);

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const handleDistanceChange = (event) => {
    const newDistance = parseInt(event.target.value, 10);
    setDistance(newDistance);
    //fetchProducts(userLocation, newDistance, userCountry);
  };

  // const [filters, setFilters] = useState({});

  const [filterOptions, setFilterOptions] = useState([]);

  const [filters, setFilters] = useState({
    ramSize: [],
    storageCapacity: [],
    size: [],
    color: [],
    skinType: [],
    fuelType: [],
    transmission: [],
    processorType: [],
    material: [],
    material2: [],
    fit: [],
    racketType: [],
    swimsuitType: [],
    HealthBeautyFormulationAndType: [],
    HealthBeautyType: [],
    HoldStrengthHealthBeauty: [],
    VolumeHealthBeauty: [],
    BodyPartHealthBeauty: [],
    VisionFrameMaterial: [],
    color_2: [],
    desktopType: [],
    storageType: [],
    screenSize: [],
    paperSize: [],
    electronicsBrand: [],
  });

  const resetCatSubcatFiltsfetchFiltProds = (
    category_id,
    subcategory_id,
  ) => {
    const resetFilters = Object.keys(filters).reduce((acc, key) => {
      acc[key] = [];
      return acc;
    }, {});
    setFilters(resetFilters);
    fetchFilteredProducts(
      searchTerm,
      userLocation,
      category_id,
      subcategory_id,
      resetFilters
    )
    window.scrollTo(0, 170);
  }

  const resetCategorySubcategoryFilters = () => {

    const resetFilters = Object.keys(filters).reduce((acc, key) => {
      acc[key] = [];
      return acc;
    }, {});
    // Update the state with the new reset filters
    setFilters(resetFilters);

    // setFilters({
    //   ramSize: [],
    //   storageCapacity: [],
    //   size: [],
    //   color: [],
    //   skinType: [],
    //   fuelType: [],
    //   transmission: [],
    //   processorType: [],
    //   material: [],
    //   material2: [],
    //   fit: [],
    //   racketType: [],
    //   swimsuitType: [],
    //   HealthBeautyFormulationAndType: [],
    //   HealthBeautyType: [],
    //   HoldStrengthHealthBeauty: [],
    //   VolumeHealthBeauty: [],
    //   BodyPartHealthBeauty: [],
    //   VisionFrameMaterial: [],
    //   color_2: [],
    //   desktopType: [],
    //   storageType: [],
    //   screenSize: [],
    //   paperSize: [],
    //   electronicsBrand: [],
    // })
  }

  const handleNewInputChange = (e) => {
    const { name, value, checked } = e.target;
    setFilters((prevFilters) => {
      // Clone previous state to avoid mutation
      const updatedFilters = { ...prevFilters };

      // Update selected items based on checkbox checked status
      if (checked) {
        updatedFilters[name] = [...updatedFilters[name], value];
      } else {
        updatedFilters[name] = updatedFilters[name].filter((item) => item !== value);
      }

      fetchFilteredProducts(
        searchTerm,
        userLocation,
        category_id,
        subcategory_id,
        updatedFilters
      )
      window.scrollTo(0, 170);

      return updatedFilters;
    });

  };

  const handleNewFilterChange = (filterName, value, isCheckbox = false) => {
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };

      if (isCheckbox) {
        // Handle checkbox input
        if (updatedFilters[filterName].includes(value)) {
          // If value is already in the array, remove it
          updatedFilters[filterName] = updatedFilters[filterName].filter(item => item !== value);
        } else {
          // If value is not in the array, add it
          updatedFilters[filterName] = [...updatedFilters[filterName], value];
        }
      } else {
        // Handle select input (assuming multiple select)
        updatedFilters[filterName] = Array.isArray(value) ? value : [value];
      }

      return updatedFilters;
    });
  };

  const handleFilterChange = (filterName, selectedValues) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: selectedValues
    }));
  };

  const camelAndSnakeToTitleCase = (str) => { //Also words for underscore
    return str
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/([A-Z])/g, ' $1')  // Add space before capital letters
      .replace(/^./, (str) => str.toUpperCase());  // Capitalize the first letter
  };


  const handleSetCategory = (category) => {
    fetchFilteredProducts(searchTerm, userLocation, category.id, subcategory_id, filters);


    resetCategorySubcategoryFilters()

    setCategory_id(category.id)
    setActiveCategory(category)
    setActiveSubcategories(category.subcategories)
    setLeftVisible(false)
    getFiltersForCategory(category.id);
    setViewingSubcategories(true)
    setSubcategoryFilters([])
    setNavTypeViewing('')

    window.scrollTo(0, 170);
  }

  const handleSetSubcategory = (subcategory) => {
    fetchFilteredProducts(searchTerm, userLocation, category_id, subcategory.id, filters);

    // setCategoryFilters([])
    setSubcategory_id(subcategory.id)
    getFiltersForSubcategory(subcategory.id);
    setNavTypeViewing('')
    window.scrollTo(0, 170);
  }

  const handleBackToCategories = () => {
    setLeftVisible(true)
    setActiveSubcategories('')
    setActiveCategory('')
    setViewingSubcategories(false)
    setSubcategory_id("")
    setCategoryFilters([])
    setSubcategoryFilters([])
  }

  const removeCategoryFilter = () => {
    const categoryId = "";
    const subcategoryId = "";

    resetCatSubcatFiltsfetchFiltProds(categoryId, subcategoryId)

    setCategory_id(categoryId)
    setSubcategory_id('')
    setCategoryFilters([])
    setLeftVisible(true)
    setActiveCategory('')
    setViewingSubcategories(false)
    setActiveSubcategories('')
    setCategoryFilters([])
    setSubcategoryFilters([])
    // resetCategorySubcategoryFilters()
    // fetchFilteredProducts(searchTerm, userLocation, categoryId, subcategoryId, filters);
  }

  const removeSubcategoryFilter = () => {
    const subcategoryId = "";

    resetCatSubcatFiltsfetchFiltProds(category_id, subcategoryId)

    setSubcategory_id('')
    setSubcategoryFilters([])
    // setLeftVisible(true)
    // setActiveCategory('')
    // setViewingSubcategories(false)
    // setActiveSubcategories('')


    // resetCategorySubcategoryFilters()
    // fetchFilteredProducts(searchTerm, userLocation, category_id, subcategoryId, filters);
  }


  const getFiltersForCategory = async (id) => {
    setCategoryFiltersLoading(true)
    try {
      const response = await axios.get(
        `${apiBaseURL}/category/${id}/product-filters`
      );
      setCategoryFilters(response.data);
      const initialFilters = response.data.reduce((acc, filter) => {
        acc[filter.name] = ""; // Initialize the filter value to an empty string
        return acc;
      }, {});
      setFilters(initialFilters);

      // setFilterOptions(response.data);
      // const filterData = response.data;

      // const initialFilters = {};
      // // Dynamically create the filters state
      // filterData.forEach(filter => {
      //   initialFilters[filter.name] = [];
      // });
      // setFilters(initialFilters);


      setCategoryFiltersLoading(false)
    } catch (error) {
      console.error("Error fetching categories filter", error);
      setCategoryFiltersLoading(false)
    }
  };

  const getFiltersForSubcategory = async (id) => {
    setSubcategoryFiltersLoading(true)
    try {
      const response = await axios.get(
        `${apiBaseURL}/subcategory/${id}/product-filters`
      );
      setSubcategoryFilters(response.data);

      const initialFilters = response.data.reduce((acc, filter) => {
        acc[filter.name] = ""; // Initialize the filter value to an empty string
        return acc;
      }, {});
      setFilters(initialFilters);
      setSubcategoryFiltersLoading(false);
    } catch (error) {
      console.error("Error fetching categories filter", error);
      setSubcategoryFiltersLoading(false)
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // Handle checkbox input separately
      if (checked) {
        setSelectedSizes((prevSizes) => [...prevSizes, value]);
      } else {
        setSelectedSizes((prevSizes) =>
          prevSizes.filter((size) => size !== value)
        );
      }
    } else {
      // Handle other input types
      switch (name) {
        case "minPrice":
          setMinPrice(value);
          break;
        case "maxPrice":
          setMaxPrice(value);
          break;
        case "type":
          setType(value);
          break;
        case "sort":
          setSort(value);
          break;
        case "condition":
          setCondition(value);
          break;
        default:
          break;
      }
    }
  };

  const handleApplyFilter = () => {
    // Make API call with filter parameters
    fetchFilteredProducts(searchTerm, userLocation, category_id, subcategory_id, filters);
    setMobileFiltersOpen(false)
    window.scrollTo(0, 170);
  };

  const resetFilters = () => {
    setDistance(20);
    setMinPrice("");
    setMaxPrice("");
    setType("");
    setSort("");
    setCondition("");
    setSelectedSizes([]);
    setCurrentPage(1);
    resetCategorySubcategoryFilters()
    setCategory_id('')
    setActiveCategory('')
    setActiveSubcategories('')
    setLeftVisible(true)
    //getFiltersForCategory('');
    setViewingSubcategories(false)
    setSubcategoryFilters([])
    setCategoryFilters([])
    setSubcategory_id('')
    setMobileFiltersOpen(false)
  }

  const handleClearFilters = (e) => {
    e.preventDefault();
    resetFilters();
  };

  const getCategoryNameFromId = (id) => {
    const category = categories.find(cat => cat.id === id);
    return category?.name
  }

  const getSubcategoryNameFromId = (categoryId, subcategoryId) => {
    const category = categories.find(cat => cat.id === categoryId);
    if (category) {
      const subcategory = category.subcategories.find(subcat => subcat.id === subcategoryId);
      return subcategory?.name
    } else {
      return {}
    }
  }

  useEffect(() => {
    resetFilters();
  }, [location])

  const fetchFilteredProducts = async (
    searchTerm,
    userLocation,
    category_id,
    subcategory_id,
    filters
  ) => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiBaseURL}/products/filter`, {
        params: {
          page: currentPage,
          searchTerm,
          category_id,
          subcategory_id,
          minPrice,
          maxPrice,
          distance,
          lat: userLocation.lat,
          lng: userLocation.lng,
          country: userLocation.country,
          type,
          sort,
          condition,
          sizes: selectedSizes.join(","), // Convert array to comma-separated string
          ...filters,
        },
      });

      setFilteredProducts(response.data);
      setLastPage(response.data.last_page);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching filtered products:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedLocation = localStorage.getItem('location');
    const parsedLocation = JSON.parse(storedLocation);
    if (parsedLocation) {
      setUserLocation(parsedLocation);
    }

    // Extract query parameters
    const queryParams = new URLSearchParams(location.search);
    const initialSearchTerm = queryParams.get("searchTerm") || "";
    const cat_id = queryParams.get("category_id") || "";
    const subcat_id = queryParams.get("subcategory_id") || "";
    const navType = queryParams.get('nav_type') || "";

    setSearchTerm(initialSearchTerm);
    setCategory_id(cat_id);
    setSubcategory_id(subcat_id);

    fetchCategories(cat_id, subcat_id, navType)
    fetchFilteredProducts(initialSearchTerm, parsedLocation, cat_id, subcat_id, filters);
  }, []);

  // TODO: Add remaining values to dependency array instead of calling fetchFilteredProduct
  // everytime. Was trying to prevent calling useEffect twice on mount
  // TODO:
  useEffect(() => {
    fetchFilteredProducts(
      searchTerm,
      userLocation,
      category_id,
      subcategory_id,
      filters
    );
    window.scrollTo(0, 170);
  }, [
    type,
    sort,
    location.search,
    currentPage,
    // category_id,
    // subcategory_id,
  ]);

  const fetchCategories = async (cat_id, subcat_id, navType) => {
    try {

      if (navType === 'category' && cat_id && !subcat_id) {
        const categoryId = Number(cat_id);

        const category = categories.find(cat => cat.id === categoryId);
        if (category) {
          handleSetCategory(category)


          setSubcategories(category.subcategories)
          setDisplayName(category.name);
          setNavTypeViewing('category')
        }
      } else if (navType === 'subcategory' && cat_id && subcat_id) {
        const categoryId = Number(cat_id);
        const subcategoryId = Number(subcat_id);

        const category = categories.find(cat => cat.id === categoryId);
        if (category) {
          const subcategory = category.subcategories.find(subcat => subcat.id === subcategoryId);
          if (subcategory) {
            handleSetCategory(category)
            handleSetSubcategory(subcategory)
            setLeftVisible(false);
            // setActiveSubcategories(category.subcategories)
            // setViewingSubcategories(true)


            setDisplayName(subcategory.name);
            setNavTypeViewing('subcategory')
          }
        }
      } else {
        setNavTypeViewing("")
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [searchTerm]);


  return (
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="bg-black fixed inset-0 bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="py-4 block lg:hidden px-4 border-r border-r-200">

                    <div className="mb-3 pr-4 border-b border-b-gray-200">
                      <h3 className="flex items-center text-base font-semibold">
                        {viewingSubcategories && <ArrowLeftIcon
                          onClick={handleBackToCategories}
                          className="mr-2 p-[2px] h-5 w-5 rounded-full hover:bg-gray-200 cursor-pointer transition duration-300"
                        />}
                        {activeCategory ? activeCategory.name : "Categories"}
                      </h3>
                      <div className="flex h-full w-full h-[11rem] overflow-x-hidden overflow-y-auto">
                        <ul className={`text-[14px] flex h-full w-full shrink-0 transform flex-col transition-transform duration-300 translate-x-0  ${isLeftVisible
                          ? "translate-x-0"
                          : "-translate-x-full"
                          }`}>
                          {categoriesLoading && <li>Loading...</li>}
                          {categories.length > 0 && categories.map((category) =>
                            <li
                              onClick={() => handleSetCategory(category)}
                              key={category.id}
                              className="py-1 cursor-pointer hover:bg-gray-100/50 border-b border-b-gray-200 last:border-b-0"
                            >
                              {category.name}
                            </li>
                          )}
                        </ul>
                        <ul className={`text-[14px] flex w-full shrink-0 transform flex-col transition-transform duration-300 ${isLeftVisible
                          ? "translate-x-full"
                          : "-translate-x-full"
                          }`}>
                          {activeSubcategories.length > 0 && activeSubcategories.map((subcategory) =>
                            <li
                              onClick={() => handleSetSubcategory(subcategory)}
                              key={subcategory.id}
                              className={`py-1 cursor-pointer hover:bg-gray-100/50 border-b border-b-gray-200 last:border-b-0 ${subcategory.id === subcategory_id && "bg-gray-100"}`}
                            >
                              {subcategory.name}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>


                    {subcategoryFilters.length > 0 ? (<div className="mb-3 pb-2 pr-4 border-b border-b-gray-200">
                      <div className="relative">
                        {subcategoryFilters.map((subcategoryFilter, index) => {
                          const valuesArray = subcategoryFilter.value.split(',');
                          return (
                            <div key={index} className="pb-2 mb-2 border-b border-b-gray-200 last:border-b-0">
                              <h3 className="flow-root mb-1 text-base font-semibold">
                                {subcategoryFilter.name}
                              </h3>
                              <div className="h-20 overflow-y-auto">
                                {valuesArray.map((value, index) => (
                                  <label className="flex mb-[2px] text-[14px] items-center" key={index}>
                                    <input
                                      type='checkbox'
                                      value={value}
                                      name={subcategoryFilter.name}
                                      onChange={handleNewInputChange}
                                      className="mr-2 focus-ring border border-gray-300 focus:ring-ADashPrimary h-4 w-4"
                                    />
                                    {value}
                                  </label>
                                ))}
                              </div>
                            </div>
                          )
                        })}
                      </div>


                    </div>) : (categoryFilters.length > 0 && <div className="mb-3 pb-2 pr-4 border-b border-b-gray-200">

                      <div className="relative">
                        {categoryFilters.map((categoryFilter, index) => {
                          const valuesArray = categoryFilter.value.split(',');
                          return (
                            <div key={index} className="pb-2 mb-2 border-b border-b-gray-200 last:border-b-0">
                              <h3 className="flow-root mb-1 text-base font-semibold">
                                {camelAndSnakeToTitleCase(categoryFilter.name)}
                              </h3>
                              <div className="max-h-20 overflow-y-auto">
                                {valuesArray.map((value, index) => (
                                  <label className="flex mb-[2px] text-[14px] items-center" key={index}>
                                    <input
                                      type='checkbox'
                                      value={value}
                                      name={categoryFilter.name}
                                      onChange={handleNewInputChange}
                                      className="mr-2 focus-ring border border-gray-300 focus:ring-ADashPrimary h-4 w-4"
                                    />
                                    {value}
                                  </label>
                                ))}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>)}



                    <div className="pr-4 border-b border-gray-200 mb-3">
                      <h3 className="flow-root text-base font-semibold">Distace</h3>
                      <label className="block pb-4">
                        <select
                          className="mt-1 block w-full rounded-lg border border-gray-600 bg-gray-50 py-2 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-teal-500"
                          value={distance}
                          onChange={handleDistanceChange}
                        >
                          <option value={20}>20 km</option>
                          <option value={30}>30 km</option>
                          <option value={500}>500 km</option>
                        </select>
                      </label>
                    </div>
                    <div className="pr-4 border-b border-gray-200 pr-4">
                      <h3 className="flow-root text-base font-semibold">Price</h3>
                      <div className="flex items-center justify-between pb-4">
                        <div className="flex items-center">
                          <span className="mr-2">$</span>
                          <label>
                            <input
                              className="mt-1 block w-full rounded-lg border border-gray-600 bg-gray-50 py-2 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-teal-500 placeholder-gray-700"
                              type="text"
                              name="minPrice"
                              placeholder="Min"
                              value={minPrice}
                              onChange={handleInputChange}
                            />
                          </label>
                        </div>
                        <div className="mx-3">-</div>
                        <div>
                          <label>
                            <input
                              className="mt-1 block w-full rounded-lg border border-gray-600 bg-gray-50 py-2 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-teal-500 placeholder-gray-700"
                              type="text"
                              name="maxPrice"
                              value={maxPrice}
                              placeholder="Max"
                              onChange={handleInputChange}
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="pr-4 border-b border-gray-200 pr-4">
                      <h3 className="flow-root text-base font-semibold">Condition</h3>
                      <div className="flex items-center justify-between pb-3">
                        <div className="flex items-center">
                          <label>
                            <input
                              type="radio"
                              name="condition"
                            />
                            New
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              name="condition"
                            />
                            Used
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              name="condition"
                            />
                            Not available
                          </label>
                        </div>
                      </div>
                    </div>


                    <div className="mt-8 mr-4 flex items-center justify-between">
                      <button
                        onClick={handleClearFilters}
                        className="text-gray-900 py-1 px-5 transition duration-300 rounded-md hover:bg-primaryColor hover:text-white border border-white hover:border-primaryColor"
                      >
                        CLEAR ALL
                      </button>

                      <button
                        type="button"
                        className="bg-transparent hover:border-transparent rounded border border-red-500 py-1 px-5 text-red-500 hover:bg-red-500 hover:text-white transition duration-300"
                        onClick={handleApplyFilter}
                      >
                        APPLY
                      </button>
                    </div>

                  </form>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="mx-auto max-w-7xl px-4 py-2 sm:px-6 lg:pt-4 lg:pb-0 lg:pt-3 lg:px-8">
          <nav className="hidden lg:block" aria-label="Breadcrumb">
            <ol
              role="list"
              className="mx-auto flex max-w-2xl items-center space-x-2 lg:max-w-7xl"
            >
              {product.breadcrumbs.map((breadcrumb, index) => (
                breadcrumb.name && (
                  <li key={breadcrumb.id}>
                    <div className="flex items-center">
                      <a
                        href={breadcrumb.href}
                        className="mr-2 text-sm font-medium text-gray-900"
                      >
                        {breadcrumb.name}
                      </a>
                      {index < product.breadcrumbs.length - 1 && (
                        <svg
                          width={16}
                          height={20}
                          viewBox="0 0 16 20"
                          fill="currentColor"
                          aria-hidden="true"
                          className="h-5 w-4 text-gray-300"
                        >
                          <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                        </svg>
                      )}
                    </div>
                  </li>
                )
              ))}
            </ol>
          </nav>
          {discount && <div className="mt-4 mb-6">
            <img src={dealAdImage} alt="deal ad" className="w-full" />
          </div>}

          <div className="mb-1 mt-2 mx-auto max-w-2xl lg:max-w-7xl lg:max-w-8xl">
            {displayName && (navTypeViewing === 'category' || navTypeViewing === 'subcategory') &&
              <div className="">
                <h3 className="text-2xl font-semibold">{displayName}</h3>
                {navTypeViewing === 'category' && <img
                  className="mt-1 mb-2"
                  src={categoryImage}
                  alt="category banner"
                />}
              </div>
            }
            {navTypeViewing !== "category" && navTypeViewing !== "subcategory" &&
              <div className="flex gap-2 lg:gap-4 flex-wrap">
                {
                  searchTerm && (
                    <FilterTag
                      label="Search Term"
                      value={searchTerm}
                    />
                  )
                }
                {
                  category_id && (
                    <FilterTag
                      label="Category"
                      value={getCategoryNameFromId(category_id)}
                      onRemove={removeCategoryFilter}
                    />
                  )
                }
                {
                  subcategory_id && (
                    <FilterTag
                      label="Subcategory"
                      value={getSubcategoryNameFromId(category_id, subcategory_id)}
                      onRemove={removeSubcategoryFilter}
                    />
                  )
                }
                {
                  distance !== 20 && distance !== '' && (
                    <FilterTag
                      label="Distance"
                      value={`${distance}km`}
                      onRemove={() => setDistance(20)}
                    />
                  )
                }
                {
                  type && (
                    <FilterTag
                      label="Type"
                      value={type}
                      onRemove={() => setType('')}
                    />
                  )
                }
                {
                  sort && (
                    <FilterTag
                      label="Sort by"
                      value={camelAndSnakeToTitleCase(sort)}
                      onRemove={() => setSort('')}
                    />
                  )
                }
                {
                  minPrice && (
                    <FilterTag
                      label="Min price"
                      value={minPrice}
                      onRemove={() => setMinPrice('')}
                    />
                  )
                }
                {
                  maxPrice && (
                    <FilterTag
                      label="Max price"
                      value={maxPrice}
                      onRemove={() => setMaxPrice('')}
                    />
                  )
                }
                {
                  condition && (
                    <FilterTag
                      label="Condition"
                      value={condition === 'na' ? 'Not Available' : condition}
                      onRemove={() => setCondition('')}
                    />
                  )
                }


              </div>
            }
          </div>
          <div className="mx-auto max-w-2xl lg:max-w-7xl md:pb-4 lg:pb-2 lg:max-w-8xl flex items-baseline justify-between border-b border-gray-200 pb-2">
            <p className="flex text-sm lg:text-md items-center">
              <span className="mr-6 text-gray-500">
                {filteredProducts?.total} Ad[s]
              </span>
              <span className="flex items-center">
                <MapPinIcon className="mr-1 h-4 w-4" aria-hidden="true" />
                {userLocation?.city}
              </span>
            </p>
            <h1 className="text-[15px] lg:text-base md:font-semi-bold flex items-center justify-between tracking-tight text-gray-900">
              <select
                name="type"
                value={type}
                onChange={handleInputChange}
                className="h-full rounded-md border p-2"
              >
                <option value="">Everything</option>
                <option value="product">Products</option>
                <option value="grocery">Groceries</option>
              </select>
            </h1>

            <div className="fixed left-1/2 transform -translate-x-1/2 md:static md:transform-none py-1 px-2 lg:py-0 lg:px-0 bottom-[65px] lg:bottom-0 lg:left-0 z-[2] bg-white lg:relative flex items-center gap-3 lg:gap-0 rounded-full md:rounded-md lg:rounded-0 border-0 md:border border-gray-200 lg:border-0 shadow-md md:shadow-none">
              <select
                name="sort"
                value={sort}
                onChange={handleInputChange}
                className="h-full rounded-full lg:rounded-md bg-white mr-2 lg:mr-0 lg:bg-white lg:border text-[13px] lg:text-base p-1 lg:p-2 text-gray-700"
              >
                <option value="">Sort</option>
                <option value="views">Most Popular</option>
                <option value="ratings">Rating</option>
                <option value="created_at">Newest Upload</option>
                <option value="price_lowest">Price: Low to High</option>
                <option value="price_highest">Price: High to Low</option>
              </select>
              {listView === "grid" ? (
                <button
                  onClick={() => setListView("list")}
                  type="button"
                  className="-m-2 md:ml-0 border-l lg:border-0 border-l-gray-300 lg:ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7"
                >
                  <span className="sr-only">View list</span>
                  <ListBulletIcon className="h-4 lg:h-5 w-4 lg:w-5" aria-hidden="true" />
                </button>
              ) : (
                <button
                  onClick={() => setListView("grid")}
                  type="button"
                  className="-m-2 border-l lg:border-0 border-l-gray-300 lg:ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7"
                >
                  <span className="sr-only">View grid</span>
                  <Squares2X2Icon className="h-4 lg:h-5 w-4 lg:w-5" aria-hidden="true" />
                </button>
              )}
              <button
                type="button"
                className="-m-2 lg:ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="sr-only">Filters</span>
                <FunnelIcon className="h-4 lg:h-5 w-4 lg:w-5 mr-2" aria-hidden="true" />
              </button>
            </div>
          </div>

          <section
            aria-labelledby="products-heading"
          >
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Filters */}
              <form className="pt-4 lg:min-h-[100vh] pb-8 hidden lg:block border-r border-r-200">
                <div className="mb-3 pr-4 border-b border-b-gray-200">
                  <h3 className="flex items-center text-base font-semibold">
                    {viewingSubcategories && <ArrowLeftIcon
                      onClick={handleBackToCategories}
                      className="mr-2 p-[2px] h-5 w-5 rounded-full hover:bg-gray-200 cursor-pointer transition duration-300"
                    />}
                    {activeCategory ? activeCategory.name : "Categories"}
                  </h3>
                  <div className="flex h-full w-full h-[11rem] overflow-x-hidden overflow-y-auto">
                    <ul className={`text-[14px] flex h-full w-full shrink-0 transform flex-col transition-transform duration-300 translate-x-0  ${isLeftVisible
                      ? "translate-x-0"
                      : "-translate-x-full"
                      }`}>
                      {categoriesLoading && <li>Loading...</li>}
                      {categories.length > 0 && categories.map((category) =>
                        <li
                          onClick={() => handleSetCategory(category)}
                          key={category.id}
                          className="py-1 cursor-pointer hover:bg-gray-100/50 border-b border-b-gray-200 last:border-b-0"
                        >
                          {category.name}
                        </li>
                      )}
                    </ul>
                    <ul className={`text-[14px] flex w-full shrink-0 transform flex-col transition-transform duration-300 ${isLeftVisible
                      ? "translate-x-full"
                      : "-translate-x-full"
                      }`}>
                      {activeSubcategories.length > 0 && activeSubcategories.map((subcategory) =>
                        <li
                          onClick={() => handleSetSubcategory(subcategory)}
                          key={subcategory.id}
                          className={`py-1 cursor-pointer hover:bg-gray-100/50 border-b border-b-gray-200 last:border-b-0 ${subcategory.id === subcategory_id && "bg-gray-100"}`}
                        >
                          {subcategory.name}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                {categoryFiltersLoading && <p>Loading Filters...</p>}
                {subcategoryFiltersLoading && <p>Loading Filters...</p>}

                {subcategoryFilters.length > 0 ? (<div className="mb-3 pb-2 pr-4 border-b border-b-gray-200">
                  <div className="relative">
                    {subcategoryFilters.map((subcategoryFilter) =>
                      <CategorySubcategoryFilters
                        handleNewInputChange={handleNewInputChange}
                        subcategoryFilter={subcategoryFilter}
                      />
                    )}
                  </div>
                </div>) : (categoryFilters.length > 0 &&
                  <div className="mb-3 pb-2 pr-4 border-b border-b-gray-200">
                    <div className="relative">
                      {categoryFilters.map((categoryFilter) =>
                        <CategorySubcategoryFilters
                          handleNewInputChange={handleNewInputChange}
                          subcategoryFilter={categoryFilter}
                        />
                      )}
                    </div>
                  </div>)}


                <div className="pr-4 border-b border-gray-200 mb-3">
                  <h3 className="flow-root text-base font-semibold">Distace</h3>
                  <label className="block pb-4">
                    <select
                      className="mt-1 block w-full rounded-lg border border-gray-600 bg-gray-50 py-2 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-teal-500"
                      value={distance}
                      onChange={handleDistanceChange}
                    >
                      <option value={20}>20 km</option>
                      <option value={30}>30 km</option>
                      <option value={500}>500 km</option>
                    </select>
                  </label>
                </div>
                <div className="pr-4 border-b border-gray-200 mb-3">
                  <h3 className="flow-root text-base font-semibold">Price</h3>
                  <div className="flex items-center justify-between pb-4">
                    <div className="flex items-center">
                      <span className="mr-2">£</span>
                      <label>
                        <input
                          className="mt-1 block w-full rounded-lg border border-gray-600 bg-gray-50 py-2 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-teal-500 placeholder-gray-700"
                          type="text"
                          name="minPrice"
                          placeholder="Min"
                          value={minPrice}
                          onChange={handleInputChange}
                        />
                      </label>
                    </div>
                    <div className="mx-3">-</div>
                    <div>
                      <label>
                        <input
                          className="mt-1 block w-full rounded-lg border border-gray-600 bg-gray-50 py-2 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-teal-500 placeholder-gray-700"
                          type="text"
                          name="maxPrice"
                          value={maxPrice}
                          placeholder="Max"
                          onChange={handleInputChange}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="pr-4 border-b border-gray-200">
                  <h3 className="flow-root text-base mb-1 font-semibold">Condition</h3>
                  <div className="flex items-center pb-4">
                    <div className="flex items-center">
                      <label htmlFor="new" className="mr-3">
                        <input
                          id="new"
                          type="radio"
                          name="condition"
                          value="new"
                          checked={condition === 'new'}
                          onChange={handleInputChange}
                          className="mr-1"
                        />
                        New
                      </label>
                    </div>
                    <div>
                      <label htmlFor="used" className="mr-3">
                        <input
                          id="used"
                          type="radio"
                          name="condition"
                          className="mr-1"
                          value="used"
                          checked={condition === 'used'}
                          onChange={handleInputChange}
                        />
                        Used
                      </label>
                    </div>
                    <div>
                      <label htmlFor="na">
                        <input
                          id="na"
                          type="radio"
                          name="condition"
                          className="mr-1"
                          value="na"
                          checked={condition === 'na'}
                          onChange={handleInputChange}
                        />
                        Not Available
                      </label>
                    </div>
                  </div>
                </div>


                <div className="mt-8 mr-4 flex items-center justify-between">
                  <button
                    onClick={handleClearFilters}
                    className="text-gray-900 py-1 px-5 transition duration-300 rounded-md hover:bg-primaryColor hover:text-white border border-white hover:border-primaryColor"
                  >
                    CLEAR ALL
                  </button>

                  <button
                    type="button"
                    className="bg-transparent hover:border-transparent rounded border border-red-500 py-1 px-5 text-red-500 hover:bg-red-500 hover:text-white transition duration-300"
                    onClick={handleApplyFilter}
                  >
                    APPLY
                  </button>
                </div>
              </form>

              {/* Product grid */}
              <div className="relative min-h-[50vh] lg:col-span-3 pt-4 flex flex-col">
                {loading && (
                  <div className="absolute bg-white bg-opacity-70 inset-0 z-[3] flex items-center justify-center">
                    <p className="mt-[20vh] lg:mt-0">
                      Loading...
                    </p>
                  </div>
                )}

                {!loading && filteredProducts?.data?.length === 0 && (
                  <div className="absolute left-0 right-0 top-0 mt-[20vh] lg:mt-[10rem] z-[1] flex items-center justify-center">
                    {navTypeViewing === "category" || navTypeViewing === "subcategory"
                      ? <p className="mt-[13vh] lg:mt-[10rem]">
                        There are no products in this category
                      </p>
                      : <p className="">
                        Your filter returned no products, you can try to widen
                        your search reach
                      </p>}
                  </div>
                )}

                {/*----------*/}

                {/* Display the filtered products */}
                <div className="bg-white flex-1">
                  <div className="mx-auto max-w-2xl lg:max-w-7xl">
                    <h2 className="sr-only">Products</h2>

                    {subcategories && navTypeViewing === 'category' && <Subcategories loading={categoriesLoading} subcategories={subcategories} />}

                    {navTypeViewing === 'subcategory' && <img
                      className="mt-1 mb-6"
                      src={subcategoryImage}
                      alt="subcategory banner"
                    />}
                    <div
                      className={`grid mb-24 ${listView === "grid"
                        ? "grid-cols-2 gap-x-3 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-4 "
                        : "grid-cols-1 gap-y-8"
                        }`}
                    >
                      {/* {Array.isArray(filteredProducts?.data) && */}
                      {filteredProducts?.data &&
                        filteredProducts.data.map((product) => (
                          <>
                            {product.type == "product" ? (
                              <Product product={product} listView={listView} />
                            ) : (
                              <Grocery product={product} listView={listView} />
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                </div>

                {/*TODO: put in component*/}
                {filteredProducts && filteredProducts.last_page > 1 && (
                  <div className="mt-8 mb-4 flex justify-center">
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className={`h-12 w-12 flex justify-center items-center mr-2 rounded-full transition duration-300 ${currentPage === 1 ? "bg-gray-200 border-red-300" : "bg-[#ff7369] hover:bg-[#e43d31]"
                        }`}
                    >
                      <ArrowLeftIcon className="w-5 h-5" />
                    </button>

                    <span className="bg-[#ededed] flex items-center rounded-[4rem]">
                      {/* Show the first page */}
                      <button
                        onClick={() => handlePageChange(1)}
                        className={`rounded-full mr-2 h-12 w-12 border-2 hover:border-[#ff6258] transition duration-300 ${currentPage === 1 && "border-[#ff6258]"} `}
                      >
                        1
                      </button>

                      {/* Show an ellipsis if needed */}
                      {currentPage > 4 && <span className="mr-2 px-3 py-2">...</span>}
                      {/* Show pages around the current page */}
                      {[...Array(lastPage).keys()].slice(
                        Math.max(1, currentPage - 2), // Start range
                        Math.min(lastPage - 1, currentPage + 1) // End range
                      ).map((page) => (
                        <button
                          key={page + 1}
                          onClick={() => handlePageChange(page + 1)}
                          className={`rounded-full mr-2 h-12 w-12 border-2 hover:border-[#ff6258] transition duration-300 ${currentPage === page + 1 && "border-[#ff6258]"}`}
                        >
                          {page + 1}
                        </button>
                      ))}

                      {/* Show an ellipsis if needed */}
                      {currentPage < lastPage - 3 && <span className="mr-2 px-3 py-2">...</span>}

                      {/* Show the last page */}
                      <button
                        onClick={() => handlePageChange(lastPage)}
                        className={`rounded-full mr-2 h-12 w-12 border-2 hover:border-[#ff6258] transition duration-300 ${currentPage === lastPage && "border-[#ff6258]"}`}
                      >
                        {lastPage}
                      </button>
                    </span>


                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === lastPage}
                      className={`h-12 w-12 flex justify-center items-center ml-2 rounded-full transition duration-300 ${currentPage === lastPage ? "bg-gray-200 border-red-300" : "bg-[#ff7369] hover:bg-[#e43d31]"
                        }`}
                    >
                      <ArrowRightIcon className="w-5 h-5" />

                    </button>
                  </div>
                )}

              </div>
            </div>
          </section>

        </main>
      </div>
    </div>
  );
};

export default Products;

import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import axios from 'axios';
import 'services/echo';

const ChatComponent = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const accessToken = useSelector((state) => state?.auth?.user?.token);
    const userId = useSelector((state) => state?.auth?.user?.user.id);

    useEffect(() => {
        fetchMessages();

        console.log('Setting up Echo listener for user:', userId);

        // Verify Pusher connection
        window.Echo.connector.pusher.connection.bind('connected', () => {
            console.log('Successfully connected to Pusher');
        });

        window.Echo.connector.pusher.connection.bind('error', (err) => {
            console.error('Failed to connect to Pusher:', err);
        });

        // Listen for new messages
        const channel = window.Echo.private(`chat.${userId}`);
        
        channel.listen('.MessageSent', (event) => {
            console.log('Received MessageSent event:', event);
            setMessages((prevMessages) => {
                console.log('Updating messages state:', [...prevMessages, event.message]);
                return [...prevMessages, event.message];
            });
        });

        console.log('Echo channels:', window.Echo.connector.channels);

        return () => {
            console.log('Cleaning up Echo listener');
            window.Echo.leave(`chat.${userId}`);
        };
    }, [userId]);

    const fetchMessages = async () => {
        try {
            const response = await axios.get("http://127.0.0.1:8000/api/v1/messages", {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            // console.log('Fetched messages:', response.data);
            setMessages(response.data);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const sendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim()) return;

        try {
            const receiverId = userId === 7 ? 1 : 7;
            console.log('Sending message to user:', receiverId);
            const response = await axios.post(
                "http://127.0.0.1:8000/api/v1/messages",
                { content: newMessage, receiver_id: receiverId },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            console.log('Message sent:', response.data);
            setNewMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <div className="flex flex-col h-96 border border-gray-300 rounded-lg">
            <div className="flex-1 overflow-y-auto p-4 space-y-2">
                {messages.map(message => (
                    <div key={message.id} className={`rounded-lg p-2 break-words ${message.sender_id === userId ? 'bg-blue-100 text-right' : 'bg-gray-100'}`}>
                        {message.content}
                    </div>
                ))}
            </div>
            <form onSubmit={sendMessage} className="flex p-4 border-t border-gray-300">
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message..."
                    className="flex-1 px-3 py-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded-r-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    Send
                </button>
            </form>
        </div>
    );
};

export default ChatComponent;
import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import ButtonLoading from "components/Loading/ButtonLoading";
import { SET_MESSAGE } from "actions/types";

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export default function NewShippingAddress({
    open,
    setOpen,
    getShippingAddresses,
    setEditingShippingAddress,
    editingShippingAddress,
}) {
    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state?.auth?.user?.token);

    const [errors, setErrors] = useState({});
    const [apiError, setApiError] = useState('');
    const [loading, setLoading] = useState(false);
    const initialShippingAddress = {
        name: "",
        address_line_1: "",
        address_line_2: "",
        latitude: 51.5074,
        longitude: -0.1278,
        city: "",
        postal_code: "",
        country: "United Kingdom",
    }
    const [shippingAddress, setShippingAddress] = useState(initialShippingAddress);

    useEffect(() => {
        setLoading(false)
        console.log('eddddmii', editingShippingAddress)
        editingShippingAddress
            ? setShippingAddress(editingShippingAddress)
            : setShippingAddress(initialShippingAddress);
        return () => {
            setEditingShippingAddress('');  // Reset shippingAddress on unmount
        };
    }, [])

    const handleShippindAddressChange = (e) => {
        const { name, value } = e.target;
        setShippingAddress((prevState) => ({
            ...prevState,
            [name]: value
        }));

        validateField(name, value);
    };

    const validateField = (fieldName, value) => {
        let errorMessage = "";

        switch (fieldName) {
            case "name":
                if (!value.trim()) {
                    errorMessage = "Name is required";
                }
                break;
            case "address_line_1":
                if (!value.trim()) {
                    errorMessage = "Address line 1 is required";
                }
                break;
            case "city":
                if (!value.trim()) {
                    errorMessage = "The city is required";
                }
                break;
            default:
                break;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: errorMessage
        }));
    };

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault()
        try {
            if (editingShippingAddress) {
                const response = await axios.post(
                    `${apiBaseURL}/shipping-addresses/${editingShippingAddress.id}`,
                    shippingAddress,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                dispatch({
                    type: SET_MESSAGE,
                    payload: { type: "success", message: "Shipping address edited succesfully" },
                });
            } else {
                await axios.post(
                    `${apiBaseURL}/shipping-addresses`,
                    shippingAddress,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                dispatch({
                    type: SET_MESSAGE,
                    payload: { type: "success", message: "Shipping address created succesfully" },
                });
            }
            getShippingAddresses()
            setApiError('');
            setOpen(false)
        } catch (err) {
            console.log(err)
            setApiError('Error creating shipping address: ' + err.response?.data?.message || err.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                                <div className="relative rounded-lg flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                                    <button
                                        type="button"
                                        className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-6"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-8 w-8 rounded-full p-1 hover:bg-gray-200 transition-all ease-in-out duration-300" aria-hidden="true" />
                                    </button>

                                    <div className="w-full">
                                        <p className="text-xl text-gray-900 mb-4">New Shipping Address</p>

                                        <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                                            {apiError && <p style={{ color: 'red' }}>{apiError}</p>}

                                            <div>
                                                <label
                                                    htmlFor="name"
                                                    className="text-xs font-semibold text-gray-500"
                                                >
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    value={shippingAddress.name}
                                                    onChange={handleShippindAddressChange}
                                                    name="name"
                                                    required
                                                    placeholder="Home, Office"
                                                    className={`mt-1 block w-full rounded ring-1 ring-gray-300 border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-blue-500 ${errors.name
                                                        ? "bg-red-50"
                                                        : "bg-gray-50"
                                                        }`}
                                                />
                                                {errors.name &&
                                                    <p
                                                        style={{ color: "red", fontSize: "13px" }}
                                                        className="mt-1"
                                                    >
                                                        {errors.name}
                                                    </p>
                                                }
                                            </div>
                                            <div>
                                                <label
                                                    htmlFor="address_line_1"
                                                    className="text-xs font-semibold text-gray-500"
                                                >
                                                    Address Line 1*
                                                </label>
                                                <input
                                                    type="text"
                                                    id="address_line_1"
                                                    name="address_line_1"
                                                    required
                                                    value={shippingAddress.address_line_1}
                                                    onChange={handleShippindAddressChange}
                                                    className={`mt-1 block w-full rounded ring-1 ring-gray-300 border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-blue-500 ${errors.address_line_1
                                                        ? "bg-red-50"
                                                        : "bg-gray-50"
                                                        }`}
                                                />

                                                {errors.address_line_1 &&
                                                    <p
                                                        style={{ color: "red", fontSize: "13px" }}
                                                        className="mt-1"
                                                    >
                                                        {errors.address_line_1}
                                                    </p>
                                                }
                                            </div>
                                            <div className="relative">
                                                <label
                                                    htmlFor="address_line_2"
                                                    className="text-xs font-semibold text-gray-500"
                                                >
                                                    Address Line 2
                                                </label>
                                                <input
                                                    type="text"
                                                    id="address_line_2"
                                                    name="address_line_2"
                                                    value={shippingAddress.address_line_2}
                                                    onChange={handleShippindAddressChange}
                                                    className={`mt-1 block w-full rounded ring-1 ring-gray-300 border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-blue-500 ${errors.address_line_2
                                                        ? "bg-red-50"
                                                        : "bg-gray-50"
                                                        }`}
                                                />
                                            </div>
                                            <div className="flex gap-3 w-full">
                                                <div className="relative w-full">
                                                    <label
                                                        htmlFor="city"
                                                        className="text-xs font-semibold text-gray-500"
                                                    >
                                                        City
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="city"
                                                        name="city"
                                                        value={shippingAddress.city}
                                                        required
                                                        onChange={handleShippindAddressChange}
                                                        className={`mt-1 block w-full rounded ring-1 ring-gray-300 border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-blue-500 ${errors.city
                                                            ? "bg-red-50"
                                                            : "bg-gray-50"
                                                            }`}
                                                    />

                                                    {errors.city &&
                                                        <p
                                                            style={{ color: "red", fontSize: "13px" }}
                                                            className="mt-1"
                                                        >
                                                            {errors.city}
                                                        </p>
                                                    }
                                                </div>
                                                <div className="relative w-full">
                                                    <label
                                                        htmlFor="postal_code"
                                                        className="text-xs font-semibold text-gray-500"
                                                    >
                                                        Postal code
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="postal_code"
                                                        name="postal_code"
                                                        value={shippingAddress.postal_code}
                                                        onChange={handleShippindAddressChange}
                                                        className={`mt-1 block w-full rounded ring-1 ring-gray-300 border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-blue-500 ${errors.postal_code
                                                            ? "bg-red-50"
                                                            : "bg-gray-50"
                                                            }`}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex pt-6 gap-3">
                                                <button
                                                    className="block w-full cursor-pointer rounded-md bg-primaryColor px-3 py-3 text-center text-sm text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryColor"
                                                >
                                                    <span className="uppercase flex items-center justify-center">
                                                        {loading && <ButtonLoading customClassName="mr-2" />}
                                                        {editingShippingAddress
                                                            ? 'Submit'
                                                            : 'Create Shipping Address'
                                                        }
                                                    </span>
                                                </button>
                                                <span
                                                    onClick={() => setOpen(false)}
                                                    className="block w-full cursor-pointer rounded-md border border-primaryColor bg-white px-3 py-3 text-center text-sm text-primaryColor shadow-sm hover:border-red-500 hover:bg-red-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    CANCEL
                                                </span>

                                            </div>
                                        </form>



                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

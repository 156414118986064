import Success from "components/front/paymentSuccess/Success";
import Header from "components/front/header/Header";
import Footer from "components/front/footer/Footer";

const PaymentSuccess = () => {
  return (
    <div>
      <Header />
      <Success />
      <Footer />
    </div>
  );
};

export default PaymentSuccess;

import { useEffect } from "react";
import Sidenav from "components/front/companyDetails/tutorials/Sidenav";
import Header from "components/front/header/Header";
import Footer from "components/front/footer/Footer";

const Settings = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />

      <main className="min-h-full bg-white">
        <div className="mx-auto max-w-2xl px-4 lg:px-8 pt-4 pb-2 md:pt-2 md:pb-4 lg:pt-8 lg:pb-4 lg:max-w-7xl lg:px-8">
          <h1 className="pb-8 text-xl font-normal uppercase tracking-tight text-gray-900 md:text-2xl">
            Tutorials
          </h1>

          <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
            {/* Left: Navigation Links */}
            <Sidenav />

            {/* Right: Content Area */}
            <main className="flex-1 p-4 md:p-6 bg-white shadow-inner">

              <div className="p-6 bg-gray-50">
                <h4 className="text-2xl font-semibold mb-4">SETTINGS</h4>
                <p className="text-gray-700 mb-6">
                  There are two divisions of the settings tab. They are:
                </p>

                <h5 className="text-xl font-semibold mb-3">UPDATE PROFILE</h5>
                <p className="text-gray-700 mb-4">
                  This section lets you upload a profile picture to replace the{' '}
                  <i className="fa fa-user-circle-o text-gray-600"></i> with a real picture of your choice. You can also edit your Name, Email, Phone Number, and Address. When you're done, just click{' '}
                  <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                    Update
                  </button>.
                </p>

                <h5 className="text-xl font-semibold mb-3">CHANGE PASSWORD</h5>
                <p className="text-gray-700 mb-4">
                  This section is for changing your password. You just need to enter your old password and replace it with the new password twice and click{' '}
                  <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                    Change Password
                  </button>.
                </p>
              </div>

            </main>
          </div>

        </div>
      </main>


      <Footer />
    </div>
  );
};

export default Settings;

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_MESSAGE } from "actions/types";
import { XMarkIcon, CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";

const TOAST_DURATION = 9000; // Duration before the toast disappears
const ANIMATION_DURATION = 300; // Animation duration for slide-in/out

const Toaster = () => {
  const [isVisible, setIsVisible] = useState(false); // Controls visibility and animation state
  const dispatch = useDispatch();
  const message = useSelector((state) => state.message);

  const handleClearMessageClick = () => {
    setIsVisible(false);
    setTimeout(() => {
      dispatch({ type: CLEAR_MESSAGE });
    }, ANIMATION_DURATION); // Delay for slide-out animation
  };

  useEffect(() => {
    if (message && (message?.message?.type === "success" || message?.message?.type === "error")) {
      // Trigger slide-out for previous toast if any
      setIsVisible(false);

      // Show new message after previous toast slides out
      const showTimeout = setTimeout(() => {
        setIsVisible(true); // Trigger slide-in animation
      }, ANIMATION_DURATION);

      // Set a timeout to hide the toast after the defined duration
      const hideTimeout = setTimeout(() => {
        setIsVisible(false); // Slide out after the duration
        setTimeout(() => {
          dispatch({ type: CLEAR_MESSAGE });
        }, ANIMATION_DURATION); // Ensure message is cleared after animation
      }, TOAST_DURATION);

      // Clean up timeouts
      return () => {
        clearTimeout(showTimeout);
        clearTimeout(hideTimeout);
      };
    }
  }, [message, dispatch]);

  // If there's no message or the message isn't a string, don't render the toaster
  if (!message || typeof message?.message?.message !== "string") {
    return null;
  }

  return (
    <div
      className={`fixed text-center left-0 right-0 z-[99999] top-20 transition-all ease-in-out duration-300 ${
        isVisible ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0"
      }`}
      aria-live="polite" // Announce the toast for screen readers
    >
      <div
        className={`md:min-w-[19rem] inline-flex items-center bg-white py-2 px-3 rounded-lg shadow-lg ${
          message?.message?.type === "success"
            ? "bg-[#b7f7c4] text-green-900"
            : "bg-[#ffb7b6] text-red-900"
        }`}
      >
        {/* Conditionally render icons based on message type */}
        <p className="flex items-center flex-1">
          {message?.message?.type === "success" ? (
            <CheckCircleIcon className="h-6 w-6 mr-2" />
          ) : (
            <ExclamationCircleIcon className="h-6 w-6 mr-2" />
          )}
          {message?.message?.message}
        </p>
        {/* Close Button */}
        <XMarkIcon
          onClick={handleClearMessageClick}
          className={`h-6 w-6 ml-6 rounded-full p-1 cursor-pointer transition-all ease-in-out duration-300 ${
            message?.message?.type === "success" ? "hover:bg-green-400" : "hover:bg-red-400"
          }`}
          aria-hidden="true"
        />
      </div>
    </div>
  );
};

export default Toaster;

/* eslint-disable */

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { HiX } from "react-icons/hi";

import Links from "./components/Links";
import {
  MdLogout,
} from "react-icons/md";
// import routes from "routes.js";
import { getRoutes } from "getNewRoutes";

const Sidebar = ({ open, onClose }) => {
  const user = useSelector((state) => state.auth.user?.user);
  const isAdmin = user?.role_id === 1 ? true : false;
  const routes = getRoutes(user?.role_id === 1 ? true : false);

  return (
    <div
      className={`sm:none duration-175 linear h-[100vh] overflow-y-auto fixed !z-50 flex min-h-full flex-col pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${open ? "translate-x-0" : "-translate-x-96"
        } ${isAdmin ? 'bg-[#0b1539]' : 'bg-[#f6e6e6]'
        }`}
    >
      <span
        className="absolute text-white top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`ml-[26px] mr-[30px] mt-[50px] flex items-center`}>
        <Link to='/' className={`mt-1 ml-1 h-2.5 font-poppins text-[26px] font-medium uppercase dark:text-white ${isAdmin ? 'text-[#bcc4f2]' : 'text-[#0b1539]'
          }`}>
          VENSLE <span className="font-light">MARKET</span>
        </Link>
      </div>
      <div className="mt-[48px] mb-[12px] h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={routes} isAdmin={isAdmin} />
      </ul>

      {/* Nav item end */}
      {/* <ul>
        <li
          className="my-[8px] px-[1.3rem] flex cursor-pointer items-center"
        >
          <MdLogout className={`ml-1 w-5 h-5 hover:text-[#bcc4f2] ${isAdmin
            ? "text-white dark:text-white"
            : "text-[#0b1539]"
            }`} />{" "}
          <p
            className={`text-[14px] text-white dark:text-white font-light ml-3 flex ${isAdmin
              ? "text-[#bcc4f2]"
              : "text-[#0b1539]"
              }`}
          >
            Log Out
          </p>
        </li>
      </ul> */}
    </div>
  );
};

export default Sidebar;

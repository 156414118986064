import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import {
  emptyCart,
} from "actions/actions";

import success from 'assets/img/front/payment/success.png';

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export default function Success() {
  const dispatch = useDispatch();
  const location = useLocation();
  const accessToken = useSelector((state) => state.auth?.user?.token);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const xwq = queryParams.get('xwq');
    console.log('sfhtionn sfsfoo sdfsdf', accessToken)
    if (xwq) {
      axios.get(`${apiBaseURL}/payment-successful`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: { xwq },
      })
        .then(response => {
          console.log('Payment successful:', response.data);
          dispatch(emptyCart());
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }, [location.search, accessToken]);

  // useEffect(() => {
  //   dispatch(emptyCart());
  // }, [dispatch]);

  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-10 sm:py-16 lg:px-8">
        <div className="text-center">
          <img class="mx-auto" src={success} />
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Payment Successfull
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Payment has been confirmed and your item is on it's way to you
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="#"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go back home
            </a>
            <a href="#" className="text-sm font-semibold text-gray-900">
              Check Dashboard <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </>
  );
}

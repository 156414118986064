import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import SectionTitle from "components/front/sectionTitle/SectionTitle"
import SkeletonLoader from 'components/front/skeletonLoader/shopCategoryLoader';
import placeholderImg from "assets/img/front/not-found/placeholder.png"

const imageBaseURL = process.env.REACT_APP_IMAGE_BASE_URL;

export default function ShopByCategories() {
	const { categories, loading } = useSelector(state => state.category);

	const getImagePath = (name) => {
		return `${imageBaseURL}/categories/${name}`;
	};
	return (
		<div className="relative bg-white">
			<div className="mx-auto max-w-2xl px-4 pb-3 lg:pt-6 lg:pb-6 sm:px-6 lg:max-w-7xl lg:px-8">
				<SectionTitle>Shop by Categories</SectionTitle>
				<div className="mt-3 lg:mt-10 overflow-hidden">
					{loading && (
						// Show Skeleton loading while data is being fetched
						<div className="w-full">
							<div className="lg:hidden">
								<SkeletonLoader itemNumber="5" />
							</div>
							<div className="hidden lg:block">
								<SkeletonLoader itemNumber="7" />
							</div>
						</div>
					)}
					{categories.length > 0 && !loading && <Swiper
						slidesPerView={4}
						spaceBetween={20}
						navigation={true}
						modules={[Navigation]}
						breakpoints={{
							640: {
								slidesPerView: 4,
								spaceBetween: 4,
							},
							768: {
								slidesPerView: 6,
								spaceBetween: 6,
							},
							1080: {
								slidesPerView: 8,
								spaceBetween: 10,
							},
						}}
						className="mySwiper !w-[106%]"
					>
						{categories.map((category) =>
							<SwiperSlide key={category.id}>
								<Link
									to={`/filter?searchTerm=&category_id=${category.id}&distance=20&nav_type=category`}
									className="flex flex-col items-center text-center cursor-pointer"
								>
									<div className="flex justify-center items-center min-h-[5rem] min-w-[5rem] rounded-full p-0 lg:p-[0.2rem] bg-gray-100/50 transition duration-300 hover:bg-gray-200">
										<LazyLoadImage
										  src={getImagePath(category.image)}
										  className="!w-[4rem] mx-auto p-[5px] lg:p-4 !h-[4rem] lg:!w-[8rem] lg:!h-[8rem] !object-contain"
										  effect="blur"
										  wrapperClassName="w-full h-full flex items-center justify-center transform hover:scale-110 !transition duration-300"
										  alt="Suggested categories"
										/>
									</div>
									<p className="p-2 text-xs lg:text-[16px] mt-1">{category.name}</p>
								</Link>
							</SwiperSlide>
						)}
						<SwiperSlide>
							<div className="invisible">
								<div className="w-[2px]">
								</div>
							</div>
						</SwiperSlide>
					</Swiper>}
				</div>
			</div>
		</div>
	);
}

import { useEffect } from "react";
import Header from "components/front/header/Header";
import Footer from "components/front/footer/Footer";
import Sidenav from "components/front/companyDetails/tutorials/Sidenav";

const HowTo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />

      <main className="min-h-full bg-white">
        <div className="mx-auto max-w-2xl px-4 lg:px-8 pt-4 pb-2 md:pt-2 md:pb-4 lg:pt-8 lg:pb-4 lg:max-w-7xl lg:px-8">
          <h1 className="pb-8 text-xl font-normal uppercase tracking-tight text-gray-900 md:text-2xl">
            Tutorials
          </h1>

          <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
            {/* Left: Navigation Links */}
            <Sidenav />

            {/* Right: Content Area */}
            <main className="flex-1 p-4 md:p-6 bg-white shadow-inner">

              <div className="p-6 bg-gray-50">
                <h4 className="text-2xl font-semibold mb-4">INTRODUCTION</h4>
                <p className="text-gray-700 mb-6">
                  Welcome to the guide on how to use the vensle.com online market platform. This comprehensive guide is to give you a good understanding of how to use this platform. On the left is a sidebar of the list of topics covered (if you're using a tablet or a smart phone the sidebar will be at the top). The list of headings covered are Welcome, Home Page, View More Page, Customers Profile, Dashboard, Products, Settings, and Message. You can navigate through these topics until you're cleared of any doubts and if you feel you're not satisfied please visit our frequently asked question (FAQ) or contact us and we will be glad to attend to you.
                </p>

                <h4 className="text-xl font-semibold mb-4">The Menu Tabs</h4>
                <ul className="list-disc list-inside pl-5 text-gray-700">
                  <li className="mb-3">
                    The logo <span className="font-semibold">vensle logo</span> will take you to the home page when you click it from any page within the website.
                  </li>
                  <li className="mb-3">
                    The search bar is to guide you to search for any item you want. You can search by the name of the product, the description, or by the location.
                  </li>
                  <li className="mb-3">
                    The <span className="font-semibold">Sign in | Register</span> button is quite self-explanatory. The purpose is simply for you to login if you're already registered or for you to register if you've never registered before. On clicking it, a pop-up box will appear so you can easily login or register without having to leave the page where you are currently. If you don't see the pop-up, then that's because the JavaScript of your browser is turned off. Please turn it on.
                  </li>
                  <li className="mb-3">
                    The <span className="font-semibold">Upload an Item</span> button is a very easy shortcut for you to click and be taken to the upload page directly for easy upload of items. However, you must be logged in before you can upload an item. If you haven't logged in already and you click it, you will be required to login.
                  </li>
                  <li className="mb-3">
                    The <span className="font-semibold">Sell/Buy Tutorial</span> button takes you to this page you're currently in. Designed to guide you on how to use the website.
                  </li>
                  <li>
                    The <span className="font-semibold">Place a Request</span> button is meant to take you directly to the page where you can place a request.
                  </li>
                </ul>
              </div>

            </main>
          </div>

        </div>
      </main>


      <Footer />
    </div>
  );
};

export default HowTo;

import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const register = (
  name,
  business_name,
  email,
  phone_number,
  address,
  password,
  password_confirmation,
  role,
) => {
  return axios.post(API_BASE_URL + "/register", {
    name,
    business_name,
    email,
    phone_number,
    address,
    password,
    password_confirmation,
    role,
  });
};

const login = async (email, password) => {
  try {
    const response = await axios.post(API_BASE_URL + "/login", { email, password });
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

/*const login = async (email, password) => {
  return axios
    .post(API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};*/

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  register,
  login,
  logout,
};

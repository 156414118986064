import { Link } from 'react-router-dom';

import Footer from "components/front/footer/Footer";
import Header from "components/front/header/Header";

export default function PaymentCancel() {
  return (
    <div>
      <Header />
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Payment cancelled</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">You seemed to have cancelled you order</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go back home
            </Link>
            <Link to="/cart" className="text-sm font-semibold text-gray-900">
              Shopping Cart <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  )
}

import React from 'react';
import Accordion from 'components/front/accordion/Accordion';

const Faqs = () => {
    return (
        <main className="min-h-full bg-white pb-4">
            <div className="mx-auto max-w-2xl px-4 lg:px-8 pt-4 pb-2 md:pt-2 md:pb-4 lg:pt-8 lg:pb-4 lg:max-w-7xl lg:px-8">
                <h1 className="pb-8 text-xl font-normal uppercase tracking-tight text-gray-900 md:text-2xl">
                    Frequently Asked Questions
                </h1>

                <div className="">
                    <Accordion />
                </div>
            </div>
        </main>
    )
}

export default Faqs;

import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import {
  ArrowLeftIcon,
  StarIcon,
} from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { MdModeEditOutline } from "react-icons/md";

import { SET_MESSAGE } from "actions/types";

import Card from "components/card";
import CompleteOrder from "./CompleteOrder";
import Feedback from "components/front/detail/Feedback";

import defaultUserProfile from "assets/img/front/profile/user.png";
import pendingDriverImage from "assets/img/front/payment/pending.png"

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

//TODO: protect route
const OrderItems = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth?.user?.token);

  const navigate = useNavigate();
  const { orderId } = useParams();

  const [orderDetails, setOrderDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [trackingDetailsOpen, setTrackingDetailsOpen] = useState(false);
  const [completeOrderOpen, setCompleteOrderOpen] = useState(false);

  const getImagePath = (name) => {
    return `${IMAGE_BASE_URL}/${name}`;
  };

  // TODO: Put in component
  const OrderStatusTag = ({ orderDetails }) => {
    // Count the number of each status
    const pendingCount = orderDetails.items.filter(
      (item) => item.status === "Pending"
    ).length;
    const ongoingCount = orderDetails.items.filter(
      (item) => item.status === "Ongoing"
    ).length;
    const completedCount = orderDetails.items.filter(
      (item) => item.status === "Completed"
    ).length;
    const deliveredCount = orderDetails.items.filter(
      (item) => item.status === "Delivered"
    ).length;
    const declinedCount = orderDetails.items.filter(
      (item) => item.status === "Declined"
    ).length;

    return (
      <>
        {/* Conditionally render the Pending tag if there are pending items */}
        {pendingCount > 0 && (
          <span className="bg-orange-300 text-orange-900 font-medium px-4 py-1 rounded-md mr-4">
            Pending pickup ({pendingCount})
          </span>
        )}

        {/* Conditionally render the Ongoing tag if there are ongoing items */}
        {ongoingCount > 0 && (
          <span className="bg-orange-300 text-orange-900 font-medium px-4 py-1 rounded-md mr-4">
            Ongoing pickup ({ongoingCount})
          </span>
        )}

        {/* Conditionally render the Completed tag if there are completed items */}
        {completedCount > 0 && (
          <span className="bg-green-200/50 text-green-800 font-medium px-4 py-1 rounded-md mr-4">
            Completed ({completedCount})
          </span>
        )}

        {/* Conditionally render the Delivered tag if there are delivered items */}
        {deliveredCount > 0 && (
          <span className="bg-green-200/50 text-green-800 font-medium px-4 py-1 rounded-md mr-4">
            Delivered ({deliveredCount})
          </span>
        )}

        {/* Conditionally render the Declined tag if there are declined items */}
        {declinedCount > 0 && (
          <span className="bg-red-300 text-red-900 font-medium px-4 py-1 rounded-md">
            Declined ({declinedCount})
          </span>
        )}
      </>
    );
  };

  // Simulate Driver actions
  const handleAcceptOrder = async () => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/orders/${orderDetails.id}/accept`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      dispatch({
        type: SET_MESSAGE,
        payload: {
          type: "success",
          message: "Simulation: Order successfully accepted",
        },
      });
    } catch (error) {
      console.error("Error accepting order:", error);
    }
  };

  const handleCompleteOrderItem = async (id) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/order-item/${id}/complete`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      dispatch({
        type: SET_MESSAGE,
        payload: {
          type: "success",
          message: "Simulation: Order item successfully completed",
        },
      });
      window.location.reload();
    } catch (error) {
      console.error("Error completing order item:", error);
    }
  };

  const handleShowFeedback = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${API_BASE_URL}/user/orders/${orderId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
        );

        setOrderDetails(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching order details:", error);
        //navigate("/admin");
        setLoading(false);
      }
    };

    fetchOrder();
  }, [orderId]);

  return (
    <div className="mt-5">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <Feedback
            open={open}
            setOpen={setOpen}
            product={{ id: 7, total_feedback: 5 }}
            setProduct={() => console.log('Product')}
          />
          {orderDetails?.items?.length > 0 && <CompleteOrder
            open={completeOrderOpen}
            setOpen={setCompleteOrderOpen}
            delivery_code={orderDetails.delivery_code}
            receiver_id={orderDetails.user_id}
            items={orderDetails.items}
            orderDetails={orderDetails}
          />}
          <div className="flex items-center w-full mb-5">
            <Link className="flex justify-center items-center font-bold cursor-pointer block h-8 w-8 rounded-full hover:bg-gray-200 transition-all ease-in-out duration-300" to="/admin/user-orders">
              <ArrowLeftIcon className="h-6 w-6" />
            </Link>
            <h1 className="ml-2 text-2xl font-medium">{orderDetails.order_number}</h1>
            <div className="">
              <span
                className={`text-xs ml-4 px-8 py-1 rounded-md ${orderDetails.paid === 1
                  ? 'bg-green-200/50 text-green-800'
                  : 'bg-red-300 text-red-900'
                  }`}
              >
                {orderDetails.paid === 1 ? 'Paid' : 'Payment Pending'}
              </span>
              <span
                className={`text-xs ml-4 px-3 py-1 rounded-md ${orderDetails.status === 'Active'
                  ? 'bg-green-200/50 text-green-800'
                  : orderDetails.status === 'Ongoing'
                    ? 'bg-orange-300 text-orange-900'
                    : orderDetails.status === 'Cancelled'
                      ? 'bg-red-300 text-red-800'
                      : ''
                  }`}
              >
                {orderDetails.status}
              </span>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-5 md:grid-cols-12">
            {!trackingDetailsOpen ?
              <div className="mt-5 col-span-8">
                <div className="col-span-12 lg:!mb-0">
                  <Card extra={"w-full h-full"}>
                    <div className="border-b p-4 border-b-gray-200 p6">
                      {orderDetails &&
                        orderDetails.items.length > 0 &&
                        <OrderStatusTag orderDetails={orderDetails} />
                      }
                      <h5 className="text-xs mt-4 text-gray-700">
                        {moment(orderDetails.created_at).format("MMMM Do, YYYY [at] hh:mma")}
                      </h5>
                    </div>
                    <div className="p-4">
                      {orderDetails.items?.length > 0 && orderDetails.items.map((order) =>
                        <div className="flex gap-4 mb-6">
                          <div className="rounded-sm h-[3.5rem] w-[3rem] bg-gray-200">
                            <img
                              className="w-full h-full object-cover"
                              src={getImagePath(order.product?.displayimage?.name)}
                              alt="order display"
                            />
                          </div>
                          <div className="flex-1">
                            <div className="flex justify-between">
                              <p>{order.name}</p>
                              <p>{order.currency}{order.price} x {order.quantity}</p>
                              <p>{order.currency}{order.price * order.quantity}</p>
                            </div>
                            <div className="mt-5">
                              <p className="text-gray-700 text-sm mb-2">Product Details</p>
                              <p className="w-[70%]">{order.description}</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Card>

                  <Card extra={"w-full p-4 mt-4 h-full"}>
                    <div className="border-b p-4 border-b-gray-200 p6">
                      <span
                        className={`${orderDetails.paid === 1
                          ? "bg-green-200/50 text-green-800"
                          : "bg-red-300 text-red-900"
                          } font-medium px-4 py-1 rounded-md`}
                      >
                        {orderDetails.paid === 1 ? "Paid" : "Payment Pending"}
                      </span>
                      <h5 className="text-xs mt-4 text-gray-700">
                        {moment(orderDetails.created_at).format("MMMM Do, YYYY [at] hh:mma")}
                      </h5>
                    </div>
                    <div className="mt-2 px-4">
                      <div className="flex mb-3 justify-between">
                        <p>Subtotal</p>
                        <p className="text-gray-700">2 items</p>
                        <p>£{orderDetails.total_price} {/* orderDetails.subtotal */} </p>
                      </div>
                      <div className="flex mb-3 justify-between">
                        <p>Shipping fee</p>
                        <p>£10{/* orderDetails.shipping_fee */}</p>
                      </div>
                      <div className="flex mb-3 text-lg font-medium justify-between">
                        <p>Total</p>
                        <p>£{+orderDetails.total_price + 10}</p>
                      </div>
                    </div>
                  </Card>
                  <p className="mt-8">Order Trails</p>
                  <Card extra={"w-full p-4 mt-4 h-full"}>
                    <div className="p-4">
                      {orderDetails.ordertrails?.length > 0 ? orderDetails.ordertrails.map((trail) =>
                        <div key={trail.id} className="mb-2">
                          <h5 className="text-xs mt-4 ml-5 mb-3 text-gray-700">
                            {moment(trail.created_at).format("MMMM Do, YYYY [at] hh:mma")}
                          </h5>
                          <p className="flex items-center">
                            <span className="block w-2 h-2 bg-black rounded-full mr-3"></span>
                            {trail.name}
                          </p>
                        </div>
                      ) : (
                        <p>Your order is yet to be picked up.</p>
                      )}
                    </div>
                  </Card>

                  {orderDetails.status !== "Completed" &&
                    <>
                      <p className="mt-8">Simulate driver activities</p>
                      <Card extra={"w-full p-4 mt-2 h-full"}>
                        <div className="flex gap-4">
                          {orderDetails.status === 'Pending' &&
                            <p onClick={handleAcceptOrder} className="hover:underline cursor-pointer">
                              Accept order
                            </p>
                          }
                          {orderDetails?.items?.length > 0 &&
                            orderDetails.items.map((item, index) => (
                              item.status !== 'Completed' && (
                                <p
                                  key={item.id}
                                  onClick={() => handleCompleteOrderItem(item.id)}
                                  className="hover:underline cursor-pointer"
                                >
                                  Pickup order {index + 1} ({item.name.slice(0, 5)}...)
                                </p>
                              )
                            ))
                          }

                          {orderDetails.status !== "Completed" && <p onClick={() => setCompleteOrderOpen(true)} className="hover:underline cursor-pointer">
                            Complete order
                          </p>}
                        </div>
                      </Card>
                    </>
                  }

                </div>
              </div>
              :
              <div className="mt-5 col-span-8">
                <Card extra={"w-full h-full p-4"}>
                  <div className="flex justify-between">
                    <p className="font-medium text-lg">Tracking Details</p>
                    <span
                      onClick={() => setTrackingDetailsOpen(false)}
                      className="flex justify-center items-center font-bold cursor-pointer block h-8 w-8 rounded-full bg-white hover:bg-gray-200 transition-all ease-in-out duration-300"
                    >
                      <XMarkIcon className="w-8 h-8 p-1" />
                    </span>
                  </div>
                </Card>
              </div>
            }

            <div className="mt-5 col-span-4">
              <Card extra={"w-full p-4 h-full"}>
                <div className="mb-6">
                  <div className="flex justify-between items-center">
                    <p className="font-medium text-lg">Customer Information</p>
                    <span className="flex justify-center items-center font-bold cursor-pointer block h-8 w-8 rounded-full bg-white hover:bg-gray-200 transition-all ease-in-out duration-300">
                      <MdModeEditOutline />
                    </span>
                  </div>
                  <form className="mt-4">
                    <div>
                      <h5 className="text-xs text-gray-700">
                        Customer Name
                      </h5>
                      <p>{orderDetails.user?.name}</p>
                    </div>
                    <div className="mt-4">
                      <h5 className="text-xs text-gray-700">
                        Contact Information
                      </h5>
                      <p>{orderDetails.user?.phone_number}</p>
                      <p>{orderDetails.user?.email}</p>
                    </div>
                    <div className="mt-4">
                      <h5 className="text-xs text-gray-700">
                        Shipping Address
                      </h5>
                      <p>1693 Bighton Oak Lane<br />Houston, TX</p>
                    </div>
                  </form>
                  <button className="mt-6 w-full py-2 rounded-md bg-[#ff5959] hover:bg-red-600 text-white">
                    VIEW MESSAGES
                  </button>
                </div>
                <div className="mt-10">
                  <div className="flex items-center justify-between">
                    <p className="font-medium text-lg">Driver Information</p>
                    <span
                      onClick={() => setTrackingDetailsOpen(true)}
                      className="text-xs text-[#ff5959] cursor-pointer hover:underline"
                    >
                      VIEW TRACKING
                    </span>
                  </div>
                  {orderDetails.driver ?
                    <>
                      <div className="flex justify-between items-center">
                        <div className="w-[4rem] h-[4rem] rounded-full bg-gray-200">
                          <img
                            className="w-full h-full object-cover rounded-full"
                            src={orderDetails.driver.profile_picture ? getImagePath(orderDetails.driver.profile_picture) : defaultUserProfile}
                            alt="order display"
                          />
                        </div>
                        <div className="flex flex-col justify-end items-end">
                          <h5 className="text-xs text-gray-700 mb-2">Driver Rating</h5>
                          <div className="flex items-center">
                            {[0, 1, 2, 3, 4].map((rating) => (
                              <StarIcon
                                key={rating}
                                className="h-[0.9rem] w-[0.9rem] ml-1 flex-shrink-0 text-orange-200"
                                aria-hidden="true"
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      <form className="mt-4">
                        <div>
                          <h5 className="text-xs text-gray-700">
                            Driver Name
                          </h5>
                          <p>{orderDetails.driver.name}</p>
                        </div>
                        <div className="mt-4">
                          <h5 className="text-xs text-gray-700">
                            Contact Information
                          </h5>
                          <p>{orderDetails.driver.phone_number}</p>
                          <p>{orderDetails.driver.email}</p>
                        </div>
                        <div className="mt-4">
                          <h5 className="text-xs text-gray-700">
                            Total Rides
                          </h5>
                          <p>23 rides</p>
                        </div>
                      </form>
                      <button className="mt-6 mb-1 w-full py-2 rounded-md bg-[#ff5959] hover:bg-red-600 text-white">
                        VIEW MESSAGES
                      </button>
                      <button onClick={handleShowFeedback} className="mt-6 w-full py-2 rounded-md border border-[#ff5959] bg-white hover:bg-[#ff5959] hover:text-white text-[#ff5959]">
                        VIEW FEEDBACKS (24)
                      </button>
                    </> : <div className="text-center mt-4">
                      <img
                        className="w-full"
                        src={pendingDriverImage}
                        alt="Pending driver selection"
                      />
                      <p>We are matching you with the closest driver</p>
                    </div>}
                </div>
                <div className="mt-10">
                  <p className="font-medium text-lg">Proof of Delivery</p>
                  <div className="flex m-2 p-2 justify-center items-center border border rounded">
                    <p className="text-lg">{orderDetails.delivery_code}</p>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderItems;

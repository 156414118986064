import React, { useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import sliderImg1 from 'assets/img/front/slider/1.png';
import sliderImg2 from 'assets/img/front/slider/2.png';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import placeholderImg from "assets/img/front/not-found/placeholder.png"

const PickupSlider = () => {
	return (
		<Swiper
		    spaceBetween={30}
		    centeredSlides={true}
		    autoplay={{
			  delay: 2500,
			  disableOnInteraction: false,
			  pauseOnMouseEnter: true,
		    }}
		    pagination={{
			  clickable: true,
		    }}
		    navigation={true}
		    modules={[Autoplay, Pagination, Navigation]}
		    className="mySwiper homeSlider"		
		>
			<SwiperSlide>
				<LazyLoadImage
				  src={sliderImg1}
				  effect="blur"
				  wrapperClassName="w-full h-full flex items-center justify-center"
				  alt="Pickup slider"
				/>
			</SwiperSlide>
			<SwiperSlide>
				<img src={sliderImg2} alt="Pickup slider" />
			</SwiperSlide>
			<SwiperSlide>
				<img src={sliderImg1} alt="Pickup slider" />
			</SwiperSlide>
		</Swiper>
	);
}

export default PickupSlider

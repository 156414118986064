import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import Card from "components/card";
import { SET_MESSAGE } from "actions/types";
import ButtonLoading from "components/Loading/ButtonLoading";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CreateCategory = () => {
    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state?.auth?.user?.token);

    const [activeTab, setActiveTab] = useState(1);
    const [loading, setLoading] = useState('');
    const [error, setError] = useState('');
    const [categories, setCategories] = useState([]);
    const [categoriesLoading, setcategoriesLoading] = useState('');
    const [subcategoriesLoading, setScategoriesLoading] = useState('');
    const [categoryData, setCategoryData] = useState({
        name: '',
        category_type_id: '',
    });
    const [subcategoryData, setSubcategoryData] = useState({
        subcategory_name: '',
        category_id: '',
    });


    const handleTabClick = (tabNumber) => {
        setError('')
        setActiveTab(tabNumber);
        setCategoryData({
            name: '',
            category_type_id: '',
        });
        setSubcategoryData({
            subcategory_name: '',
            category_id: '',
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCategoryData({
            ...categoryData,
            [name]: value,
        });
    };

    const handleSubcategoryInputChange = (e) => {
        const { name, value } = e.target;
        setSubcategoryData({
            ...subcategoryData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(
                `${API_BASE_URL}/categories`,
                categoryData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            console.log(response)
            setCategories([...categories, response.data.category]);
            setError('');
            setCategoryData({
                name: '',
                category_type_id: '',
            });
            dispatch({
                type: SET_MESSAGE,
                payload: { type: "success", message: "Category created succesfully" },
            });
            setLoading(false);
        } catch (err) {
            console.log(err)
            setLoading(false);
            setError('Error creating category: ' + err.response?.data?.message || err.message);
        }
    };

    const handleCreateSubcategory = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(
                `${API_BASE_URL}/subcategories`,
                {
                    name: subcategoryData.subcategory_name,
                    category_id: subcategoryData.category_id
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            console.log(response)
            //setCategories([...categories, response.data.category]);
            setError('');
            setSubcategoryData({
                subcategory_name: '',
                category_id: '',
            });
            dispatch({
                type: SET_MESSAGE,
                payload: { type: "success", message: "Subcategory created succesfully" },
            });
            setLoading(false);
        } catch (err) {
            console.log(err)
            setLoading(false);
            setError('Error creating subcategory: ' + err.response?.data?.message || err.message);
        }
    };

    useEffect(() => {
        const fetchAllCategories = async () => {
            setcategoriesLoading(true);
            try {
                const response = await axios.get(`${API_BASE_URL}/categories/all`);
                setCategories(response.data.categories);
                setcategoriesLoading(false);
            } catch (error) {
                console.error("Error fetching categories:", error);
                setcategoriesLoading(false);
            }
        };

        fetchAllCategories();
    }, []);

    return (
        <div>
            <div className="flex mb-8 justify-center overflow-x-auto overflow-y-hidden whitespace-nowrap border-b border-gray-200 dark:border-gray-700">
                <button
                    className={`bg-transparent -mb-px inline-flex h-10 w-full items-center justify-center whitespace-nowrap border-b-2 pr-4 text-left transition duration-300 focus:outline-none ${activeTab === 1
                        ? "border-red-500 text-red-600 dark:border-red-400 dark:text-red-300"
                        : "border-transparent cursor-base text-gray-700 hover:border-gray-400 dark:text-white"
                        }`}
                    onClick={() => handleTabClick(1)}
                >
                    Category
                </button>
                <button
                    className={`bg-transparent -mb-px inline-flex h-10 w-full items-center justify-center whitespace-nowrap border-b-2 px-4 text-center focus:outline-none ${activeTab === 2
                        ? "border-red-500 text-red-600 dark:border-red-400 dark:text-red-300"
                        : "border-transparent cursor-base text-gray-700 hover:border-gray-400 dark:text-white"
                        }`}
                    onClick={() => handleTabClick(2)}
                >
                    Subcategory
                </button>
            </div>

            {console.log('med cat', categories)}
            {activeTab === 1 ?
                <Card extra={"w-full p-4 h-full"}>
                    <div className="mb-8 w-full">
                        <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                            New category
                        </h4>
                        <div className="mt-2">
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                            <form
                                onSubmit={handleSubmit}
                                class="mt-2 flex flex-col space-y-4"
                            >
                                <div class="relative">
                                    <label
                                        for="name"
                                        class="text-xs font-semibold text-gray-500"
                                    >
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={categoryData.name}
                                        onChange={handleInputChange}
                                        className="mt-2 block w-full rounded border-gray-300 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-1 focus:ring-blue-400 bg-gray-50"
                                    />
                                </div>
                                <div class="relative">
                                    <label
                                        for="category_type_id"
                                        class="text-xs font-semibold text-gray-500"
                                    >
                                        Category type id
                                    </label>
                                    <input
                                        type="text"
                                        placeholder='1 - Groceries, 2 - Pickup and Requests'
                                        id="category_type_id"
                                        name="category_type_id"
                                        value={categoryData.category_type_id}
                                        onChange={handleInputChange}
                                        className="mt-2 block w-full rounded border-gray-300 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-1 focus:ring-blue-400 bg-gray-50"
                                    />
                                </div>
                                <div className="mt-4">
                                    <button
                                        disabled={loading ? true : false}
                                        type="submit"
                                        className="flex justify-center items-center linear mt-3 w-full rounded-xl bg-red-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-500/80 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
                                    >
                                        {loading && <ButtonLoading />} Create
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Card>
                :
                <Card extra={"w-full p-4 h-full"}>
                    <div className="mb-8 w-full">
                        <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                            New Subcategory
                        </h4>
                        <div className="mt-2">
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                            <form
                                onSubmit={handleCreateSubcategory}
                                class="mt-2 flex flex-col space-y-4"
                            >
                                <div class="relative">
                                    <label
                                        for="subcategory_name"
                                        class="text-xs font-semibold text-gray-500"
                                    >
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        id="subcategory_name"
                                        name="subcategory_name"
                                        value={subcategoryData.subcategory_name}
                                        onChange={handleSubcategoryInputChange}
                                        className="mt-2 block w-full rounded border-gray-300 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-1 focus:ring-blue-400 bg-gray-50"
                                    />
                                </div>
                                <div class="relative">
                                    <label
                                        for="category_id"
                                        class="text-xs font-semibold text-gray-500"
                                    >
                                        Category id
                                    </label>
                                    <input
                                        type="text"
                                        name="category_id"
                                        value={subcategoryData.category_id}
                                        onChange={handleSubcategoryInputChange}
                                        className="mt-2 block w-full rounded border-gray-300 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-1 focus:ring-blue-400 bg-gray-50"
                                    />
                                </div>
                                <div className="mt-4">
                                    <button
                                        disabled={loading ? true : false}
                                        type="submit"
                                        className="flex justify-center items-center linear mt-3 w-full rounded-xl bg-red-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-500/80 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
                                    >
                                        {loading && <ButtonLoading />} Create
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Card>
            }

            <div className='mt-4'>
                <h3>All Categories</h3>
                <ul>
                    <li className='flex font-medium text-lg'>
                        {activeTab === 2 && <span className='w-10'>Id</span>}
                        {activeTab === 1 &&
                            <span className='w-40'>Category Type Id</span>
                        }
                        <span>Name</span>
                    </li>
                    {categoriesLoading && <p>Loading...</p>}
                    {categories.length > 0 && categories.map((category) => (
                        <li className='flex' key={category.id}>
                            {activeTab === 2 &&
                                <span className='w-10'>{category.id}</span>
                            }
                            {activeTab === 1 &&
                                <span className='w-40'>{category.category_type_id}</span>
                            }
                            <span className='flex-1'>
                                {category.name}
                                {activeTab === 2 &&
                                    <p className='text-xs text-gray-700'>
                                        (
                                        {category.subcategories.map(subcategory => <span>{subcategory.name}, </span>)}
                                        )
                                    </p>
                                }
                            </span>
                        </li>
                    ))}
                </ul>

            </div>

        </div>
    );
};

export default CreateCategory;

import { useEffect } from "react";
import Header from "components/front/header/Header";
import Faqs from "components/front/companyDetails/faqs/Faqs"
import Footer from "components/front/footer/Footer";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />

      <main className="min-h-full bg-white pb-4">
        <div className="mx-auto max-w-2xl px-4 lg:px-8 pt-4 pb-2 md:pt-2 md:pb-4 lg:pt-8 lg:pb-4 lg:max-w-7xl lg:px-8">
          <h1 className="pb-8 text-xl font-normal uppercase tracking-tight text-gray-900 md:text-2xl">
            Privacy Policy
          </h1>

          <div className="p-6 rounded-lg shadow-md">
            <div className="mb-8">
              <p className="text-base text-gray-700 mb-4">
                This Privacy Policy explains what personal data is collected when you use
                the Vensle.com website or any Vensle.com mobile application and the
                services provided through it.
              </p>
              <p className="text-base text-gray-700 mb-2 font-semibold">
                By Using the Service, You Promise Us That
              </p>
              <ul className="list-disc list-inside text-base text-gray-700 mb-4">
                <li>You have read, understood, and agreed to this Privacy Policy, and that</li>
                <li>
                  You are over 16 years of age or 18 years in some countries or have had
                  your parent or guardian read and agree to this Privacy Policy for you.
                </li>
                <li>
                  If you do not agree or are unable to make this promise, you must not use
                  the Service. In such case, you must contact the support team via online
                  chat or email to (a) request deletion of your account and data.
                </li>
              </ul>
            </div>

            <div className="mb-8">
              <p className="text-base text-gray-700 mb-4">
                Vensle Ltd. is a registered company with the British government. Vensle
                Inc. will be responsible for storing your personal data provided by you on
                this website. We collect data you give us voluntarily (for example, an
                email address). We also collect data automatically (for example, your IP
                address).
              </p>
              <p className="text-base text-gray-700 mb-2 font-semibold">Data you give us.</p>
              <p className="text-base text-gray-700 mb-4">
                You may be asked to provide us with information about yourself when you
                register for and/or use the Service. This information includes: first
                name, phone number, email (together “Required Information”), last name,
                photo, address details, working hours. To use our Service and register an
                account, you will need to provide Required Information. You will be able
                to use the Service even if you do not give this data to us, but some
                Service’s functionality may be limited to you (for example, if you do not
                register an account, you will not be able to chat with other users, sell
                items, place a request, etc.). Sometimes you may also need to provide us
                with additional information in communication with our Support Team in
                order to fulfill your request (for example, if your account was previously
                blocked, we may ask you to confirm your identity by providing an ID
                document).
              </p>

              <p className="text-base text-gray-700 mb-2 font-semibold">
                Data provided to us by third parties.
              </p>
              <p className="text-base text-gray-700 mb-4">
                When you decide to log in using Facebook or Google, we get personal data
                from your Facebook or Google account. This includes your profile image,
                name, and Facebook ID, Google ID, friends list. For more information,
                please refer to the Facebook Permissions Reference (describes the
                categories of information, which Facebook may share with third parties and
                the set of requirements) and to the Facebook Data policy. In addition,
                Facebook lets you control the choices you made when connecting your
                Facebook profile to the App on their Apps and Websites page. To know more
                about how Google processes your data, visit its Privacy Policy.
              </p>

              <p className="text-base text-gray-700 mb-2 font-semibold">Data we collect automatically.</p>
              <ul className="list-disc list-inside text-base text-gray-700 mb-4">
                <li>
                  <b>Device and Location data.</b> We collect data from your device. Examples of such data
                  include language settings, IP address, time zone, type and model of a
                  device, device settings, operating system, Internet service provider,
                  mobile carrier, hardware ID, and Facebook ID.
                </li>
                <li>
                  <b>Usage data.</b> We record how you interact with our Service. For example, we log the
                  features, and content you interact with, how often you use the Service,
                  how long you are on the Service, what sections you use, how many ads you
                  watch.
                </li>
                <li>
                  <b>Cookies.</b> A cookie is a small text file that is stored on a user's computer for
                  record-keeping purposes. Cookies can be either session cookies or
                  persistent cookies. A session cookie expires when you close your browser
                  and is used to make it easier for you to navigate our Service. A
                  persistent cookie remains on your hard drive for an extended period of
                  time. We also use tracking pixels that set cookies to assist with
                  delivering online advertising. Cookies are used, in particular, to
                  automatically recognize you the next time you visit our Website. As a
                  result, the information, which you have earlier entered in certain
                  fields on the Website may automatically appear the next time when you
                  use our Service. Cookie data will be stored on your device and most of
                  the times only for a limited time period.
                </li>
              </ul>
            </div>

            <div className="mb-8">
              <p className="text-base text-gray-700 mb-4 font-semibold">How We Use Your Data</p>
              <p className="text-base text-gray-700 mb-4">
                We use your data to provide you with the best experience possible, to
                communicate with you, and to improve our Service. These are the purposes
                for which we use your data:
              </p>
              <ul className="list-disc list-inside text-base text-gray-700 mb-4">
                <li>to enable you to use our Service</li>
                <li>to contact you about your use of our Service</li>
                <li>to operate, maintain, and improve our Service</li>
                <li>to send you marketing communications that we believe may be of interest to you</li>
                <li>to respond to your comments and questions and provide customer service</li>
                <li>to analyze your usage of the Service and provide better recommendations</li>
                <li>to identify, investigate, and prevent activities that may violate our policies or be illegal</li>
                <li>to protect the rights, property, and safety of our users and third parties</li>
              </ul>
            </div>

            <div className="mb-8">
              <p className="text-base text-gray-700 mb-4 font-semibold">How We Share Your Data</p>
              <p className="text-base text-gray-700 mb-4">
                We share your data with third parties in the following cases:
              </p>
              <ul className="list-disc list-inside text-base text-gray-700 mb-4">
                <li>when you use our Service, we share your data with third-party services you have connected to your account</li>
                <li>we may share your data with our affiliates and subsidiaries</li>
                <li>
                  we may share your data with third-party vendors, consultants, and other
                  service providers who work for us and need access to your data to do that
                  work
                </li>
                <li>
                  we may share your data with law enforcement, government authorities, or
                  other third parties if we believe it is necessary to comply with the law,
                  to protect the safety of any person, or to protect the rights and property
                  of Vensle, its affiliates, or our users
                </li>
                <li>
                  we may share your data with third parties in connection with any merger,
                  sale of company assets, financing, or acquisition of all or a portion of
                  our business by another company
                </li>
                <li>with your consent or at your direction</li>
              </ul>
            </div>

            <div className="mb-8">
              <p className="text-base text-gray-700 mb-4 font-semibold">How You Can Access and Control Your Data</p>
              <p className="text-base text-gray-700 mb-4">
                You can view, edit, or delete your personal data at any time by accessing
                your account settings on the Service. You can also contact us at any time to
                request access to your personal data or to delete your account.
              </p>
              <p className="text-base text-gray-700 mb-4">
                If you would like to stop receiving marketing communications from us, you
                can opt-out by following the instructions included in those communications
                or by contacting us directly.
              </p>
            </div>

            <div className="mb-8">
              <p className="text-base text-gray-700 mb-4 font-semibold">Data Retention</p>
              <p className="text-base text-gray-700 mb-4">
                We retain your personal data for as long as your account is active or as
                needed to provide you with our Service. We also retain your personal data as
                necessary to comply with our legal obligations, resolve disputes, and
                enforce our agreements.
              </p>
            </div>

            <div className="mb-8">
              <p className="text-base text-gray-700 mb-4 font-semibold">Data Security</p>
              <p className="text-base text-gray-700 mb-4">
                We use reasonable security measures to protect your personal data from loss,
                misuse, and unauthorized access, disclosure, alteration, or destruction. We
                also take steps to ensure that the third parties we work with implement
                reasonable security measures to protect your personal data.
              </p>
            </div>

            <div className="mb-8">
              <p className="text-base text-gray-700 mb-4 font-semibold">Changes to This Privacy Policy</p>
              <p className="text-base text-gray-700 mb-4">
                We may update this Privacy Policy from time to time. We will notify you
                about significant changes in the way we treat personal data by sending a
                notice to the email address associated with your account or by placing a
                prominent notice on our Service. Your continued use of the Service after
                any such update will constitute your acknowledgment of the changes and your
                consent to abide and be bound by the updated Privacy Policy.
              </p>
            </div>

            <div className="mb-8">
              <p className="text-base text-gray-700 mb-4 font-semibold">Contact Us</p>
              <p className="text-base text-gray-700 mb-4">
                If you have any questions or concerns about this Privacy Policy, please
                contact us at privacy@vensle.com.
              </p>
            </div>

            <div>
              <p className="text-base text-gray-700">
                Effective as of December 2019.
              </p>
            </div>


          </div>


        </div>
      </main>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;

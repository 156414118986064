import React, { useRef } from "react";

const AccordionItem = ({ title, content, isOpen, onClick }) => {
    const contentRef = useRef(null);
  
    return (
      <li className="relative border-b border-gray-200">
        <button
          type="button"
          className="w-full px-8 py-6 text-left"
          onClick={onClick}
        >
          <div className="flex items-center justify-between">
            <span className="text-lg font-medium text-gray-900">{title}</span>
            <span className="text-lg font-medium text-gray-900">
              {isOpen ? "-" : "+"}
            </span>
          </div>
        </button>
        <div
          ref={contentRef}
          className={`relative overflow-hidden transition-max-height duration-700 ease-in-out ${
            isOpen ? "max-h-screen" : "max-h-0"
          }`}
          style={{
            maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : "0px",
          }}
        >
          <div className="p-6">
            <p className="text-gray-700">{content}</p>
          </div>
        </div>
      </li>
    );
  };

  export default AccordionItem;
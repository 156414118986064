export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";


export const Types = {
    LOGIN: 'LOGIN',
    ADD_USER: 'ADD_USER',
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
    FORM_SUBMITION_STATUS: 'FORM_SUBMITION_STATUS'
  }
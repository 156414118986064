import { XMarkIcon } from "@heroicons/react/24/outline";

const FilterTag = ({ label, value, onRemove = false }) => {
    return (
        <p className="text-[#ff5959] bg-[#fcecec] flex items-center text-sm px-3 py-[0.1rem] rounded-[7px]">
            {label}: <span className="ml-1 capitalize">{value}</span>
            {onRemove && <XMarkIcon
                onClick={onRemove}
                className="w-4 h-4 ml-2 p-[1px] cursor-pointer rounded-full transition duration-300 border border-[#ff5959] hover:bg-[#ff5959] hover:text-white"
            />}
        </p>
    );
};

export default FilterTag;
import React, { useEffect, useState } from "react";

const CategorySubcategoryFilters = ({ subcategoryFilter, handleNewInputChange }) => {
    const valuesArray = subcategoryFilter.value.split(',');

    // Initialize the state as an object where each checkbox's value is a key with a boolean (unchecked by default)
    const [checkedState, setCheckedState] = useState(
        valuesArray.reduce((acc, value) => ({ ...acc, [value]: false }), {})
    );

    useEffect(() => {
        // Reset all checkboxes to unchecked when the component mounts
        setCheckedState(
            valuesArray.reduce((acc, value) => ({ ...acc, [value]: false }), {})
        );
    }, [subcategoryFilter]);

    const handleCheckboxChange = (e, value) => {
        setCheckedState(prevState => ({
            ...prevState,
            [value]: !prevState[value] // Toggle the checked state of the clicked checkbox
        }));
        handleNewInputChange(e); // Call the parent handler for this change
    };

    return (
        <div className="pb-2 mb-2 border-b border-b-gray-200 last:border-b-0">
            <h3 className="flow-root mb-1 text-base font-semibold">
                {subcategoryFilter.display_name}
            </h3>
            <div className="h-20 overflow-y-auto">
                {valuesArray.map((value, index) => (
                    <label className="flex mb-[2px] text-[14px] items-center" key={index}>
                        <input
                            type="checkbox"
                            value={value}
                            name={subcategoryFilter.name}
                            onChange={(e) => handleCheckboxChange(e, value)}
                            checked={checkedState[value] || false}
                            className="mr-2 focus-ring border border-gray-300 focus:ring-ADashPrimary h-4 w-4"
                        />
                        {value}
                    </label>
                ))}
            </div>
        </div>
    );
};

export default CategorySubcategoryFilters;

import React, { useState, useRef, useEffect } from "react";

import AccordionItem from "./AccordionItem";

const Accordion = () => {
    const [selected, setSelected] = useState(null);

    // Initialize the state based on the expanded property
    useEffect(() => {
        const defaultItem = accordionItems.find(item => item.expanded);
        if (defaultItem) {
            setSelected(defaultItem.id);
        }
    }, []);

    const toggleAccordion = (item) => {
        setSelected((prevSelected) => (prevSelected !== item ? item : null));
    };

    // Accordion items with expanded property
    const accordionItems = [
        {
            id: 1,
            title: "What is vensle.com all about?",
            content: "Vensle.com is an online marketplace that bring buyers and sellers in a neighbourhood together. You can display all the product you sell if you have a shop and you can also sell off your personal used items as an individual. It is very easy to use. If you're not cleared enough and you still want to make more findings, please feel free to send us your questions via info@vensle.com.",
            expanded: true, // This item will be expanded by default
        },
        {
            id: 2,
            title: "How can I upload my product on vensle.com?",
            content: "It is very easy to upload your product on vensle.com. You can click on the Upload an item on the menu bar or you can go to the dashboard and click the product tab on the side bar (if you are using a mobile device or a tablet and you can't see the side bar please click on the expand button so you can see the side bar). Then click on upload new item, fill in the details and upload. That's all.",
            expanded: false,
        },
        {
            id: 3,
            title: "Why must product be pending when I upload?",
            content: "This is to avoid the upload of dirty, pornographic and contents that are disturbing. As much as possible we want to make vensle.com a comfortable platform for all users.",
            expanded: false,
        },
        {
            id: 4,
            title: "How can I make my product among the featured products that displays on the homepage?",
            content: "The featured products are selected at random and it can be anyone's product. However you can ensure the picture of your product is clear enough and the category is properly chosen to stand a chance of your product being selected among the featured products.",
            expanded: false,
        },
        {
            id: 5,
            title: "Are there products I cannot sell or request on vensle.com?",
            content: "Yes there are products you cannot sell or request on vensle.com. Products like ammunition of any kind, drugs, pornographic contents of all types and any contraband according to the law of your locality are all prohibited on vensle.com. To better understand this please read the Terms and Conditions of use.",
            expanded: false,
        },
        {
            id: 6,
            title: "How can I make my product among the featured products that displays on the homepage?",
            content: "The featured products are selected at random and it can be anyone's product. However you can ensure the picture of your product is clear enough and the category is properly chosen to stand a chance of your product being selected among the featured products.",
            expanded: false,
        },
        {
            id: 7,
            title: "Are there products I cannot sell or request on vensle.com?",
            content: "Yes there are products you cannot sell or request on vensle.com. Products like ammunition of any kind, drugs, pornographic contents of all types and any contraband according to the law of your locality are all prohibited on vensle.com. To better understand this please read the Terms and Conditions of use.",
            expanded: false,
        },
        {
            id: 8,
            title: "Can I upload an item without registering or creating an account?",
            content: "No please. You can't. You must create an account before you can upload any item.",
            expanded: false,
        },
        {
            id: 9,
            title: "How can I make my product among the featured products that displays on the homepage?",
            content: "The featured products are selected at random and it can be anyone's product. However you can ensure the picture of your product is clear enough and the category is properly chosen to stand a chance of your product being selected among the featured products.",
            expanded: false,
        },
        {
            id: 10,
            title: "Why must product be pending when I upload?",
            content: "This is to avoid the upload of dirty, pornographic and contents that are disturbing. As much as possible we want to make vensle.com a comfortable platform for all users.",
            expanded: false,
        },
    ];

    return (
        <div className="w-full">
            <ul className="shadow-box">
                {accordionItems.map((item) => (
                    <AccordionItem
                        key={item.id}
                        title={item.title}
                        content={item.content}
                        isOpen={selected === item.id}
                        onClick={() => toggleAccordion(item.id)}
                    />
                ))}
            </ul>
        </div>
    );
};

export default Accordion;

import { useEffect } from "react";
import Header from "components/front/header/Header";
import Faqs from "components/front/companyDetails/faqs/Faqs"
import Footer from "components/front/footer/Footer";

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Faqs />
      <Footer />
    </div>
  );
};

export default Faq;

import { useEffect } from "react";
import Header from "components/front/header/Header";
import Faqs from "components/front/companyDetails/faqs/Faqs"
import Footer from "components/front/footer/Footer";

const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />

      <main className="min-h-full bg-white pb-4">
        <div className="mx-auto max-w-2xl px-4 lg:px-8 pt-4 pb-2 md:pt-2 md:pb-4 lg:pt-8 lg:pb-4 lg:max-w-7xl lg:px-8">
          <h1 className="pb-8 text-xl font-normal uppercase tracking-tight text-gray-900 md:text-2xl">
            Terms of Use
          </h1>


          <div className="p-6 bg-gray-100 text-gray-800 max-w-4xl mx-auto">
            <div className="sections space-y-4">
              <p>
                If you browse or otherwise access any content or data on the vensle.com website where this Terms and Condition appears in the footer, and to any vensle.com application, service, or tool (collectively <strong>"Resource"</strong> or <strong>"Service"</strong>) you agree to be bound by these terms. If you do not agree to these terms, please do not use this Resource.
              </p>
              <p>
                By using the Resource with or without a Registered account, regardless of how you access or use it, including through mobile devices you are accepting the terms of this Terms of Conditions and any applicable posted guidelines for the Service.
              </p>
              <p>
                These Terms of Conditions shall be a legally binding agreement between the User and Administrator, which subject is a provision of access to use of the Resource and to its functionality to the User. Among these Terms of Conditions, the agreement between the User and the Administrator comprises also all specific documents, by which a provision of access to use of the Resource is regulated, including <u><a href="https://vensle.com/backend/policy/privacy-policy" className="text-blue-500 hover:underline">Privacy Policy</a></u>, <u><a href="https://vensle.com/backend/policy/product-listing" className="text-blue-500 hover:underline">Product Listing Policy</a></u> and other documents as well being developed by the Administrator from time to time.
              </p>
              <p>
                Administrator reserves the right to change Terms and Condition at any time by publishing the new Terms and Conditions on the Resource. By continuing to use the Service you are indicating your acceptance to be bound by the amended Terms and Conditions.
              </p>
              <p>
                <strong>"Administrator"</strong>, <strong>"we"</strong> or <strong>"us"</strong> - Local Content Administrator or other entity that exercises control over the Local Content Administrator, which renders the Services to the Users.
              </p>
              <p>
                <strong>"Local Content Administrator"</strong> — Vensle Ltd., an entity incorporated under the laws of the United Kingdom.
              </p>
              <p>
                <strong>"User"</strong>, <strong>"you"</strong>, <strong>"your"</strong> – means any natural person eligible to conclude an agreement under the applicable law with Administrator and using any of Services with or without a Registered account. The Users may also act on behalf of the company that provides goods and services and intends to post announcements to the Resource. In this case, Users shall be empowered for such representation in a written form.
              </p>
              <p>
                <strong>"Register entry/account"</strong> — an electronic User area within functional system of the Resource, with a help of which he/she is able to manage his/her announcements on the Resource.
              </p>
              <ol className="list-decimal list-inside pl-6 space-y-2">
                <li>
                  <strong>General provisions</strong>
                  <p className="mt-2">
                    1.1. For the registration of an account, you shall provide necessary veridical and current information for the purpose of generation of a User account, which includes User’s unique login (email address), and a Resource password, as well as his/her surname and name. Resource Registration Form may require the User to provide more detailed information.
                  </p>
                  <p className="mt-2">
                    1.2. The resource is a marketplace that allows users to offer, sell by posting an announcement and buy just about anything in a variety of pricing formats and locations. The actual contract for sale is directly between Users. In such a manner, the Administrator is not a party of the transaction, but it only provides a communication trade platform for the posting of announcements. We also have no control over and do not guarantee the existence, quality, safety, or legality of items advertised; the truth or accuracy of users' content or listings; the ability of sellers to sell items; the ability of buyers to pay for items; or that a buyer or seller will actually complete a transaction or return an item.
                  </p>
                  <p className="mt-2">
                    1.3. Administrator does not pursue actions focused on checking materials posted by Users and expressly disclaims all responsibilities in relation to materials posted by Users.
                  </p>
                  <p className="mt-2">
                    1.4. Administrator prevents infringement of copyright and intellectual property right infringement in the course of using the Resource and can delete any User material, which infringes intellectual property rights, at its own discretion without prior notice. We also may terminate Users’ access to the Resource, in the event that such Users repeatedly infringe rights or commit any actions in contrast to these Terms and Conditions.
                  </p>
                  <p className="mt-2">
                    1.5. We do not charge you in any way for using this service.
                  </p>
                </li>
                <li>
                  <strong>Using Resource</strong>
                  <p className="mt-2">
                    2.1. Using the Resource, the User shall:
                  </p>
                  <ul className="list-disc list-inside pl-6 space-y-2">
                    <li>2.1.1. Provide veridical, complete, and current data during registration, ensure them being updated;</li>
                    <li>2.1.2. The User shall immediately change data for accessing to the Resource if he/she has a suspicion that his/her email address and password used for entering to the Resource were disclosed or probably used by third parties;</li>
                    <li>2.1.3. Notify the Administrator of unauthorized access to the personal account and/or of unauthorized access to and/or use of User’s login and password;</li>
                    <li>2.1.4. Prevent other Users from getting access to the personal account or any specific information contained on it, if this can lead to violation of the laws of your country and/or these Terms and Conditions;</li>
                    <li>2.1.5. Avoid posting information and objects (including references hereto) to the Resource, which can infringe other persons’ rights and interests;</li>
                    <li>2.1.6. Avoid posting information and objects (including references hereto) to the Resource prohibited by these Terms and Conditions and by applicable law.</li>
                  </ul>
                  <p className="mt-2">
                    2.2. Using the Resource, the User shall not:
                  </p>
                  <ul className="list-disc list-inside pl-6 space-y-2">
                    <li>2.2.1. Log in as a User on behalf or instead of another person (“false account”). However, the User can log in for and on behalf of other individual or legal entity subject to receipt of necessary well-documented authorities</li>
                    <li>2.2.2. Confuse User concerning his/her personality using login and password of any other registered User;</li>
                    <li>2.2.3. Illegally download, store, post, distribute or provide access to or in any other way use the intellectual property of Users and the third parties;</li>
                    <li>2.2.4. Perform bulk mailing to the addresses of other Resource Users without their consent;</li>
                    <li>2.2.5. Use software and pursue any other actions aimed at interference with the normal operation of the Resource or Users' personal areas;</li>
                    <li>2.2.6. Download, store, post, distribute, and provide access to, or in any other way use viruses and other malware;</li>
                    <li>2.2.7. In any way, including, but not limited to, fraudulently, by way of breach of faith or crack, endeavor to get access to other User’s login and password;</li>
                    <li>2.2.8. Perform illegal collection and processing of other individuals’ personal data;</li>
                    <li>2.2.9. Use the Resource otherwise but as provided herein, except when such actions were directly permitted to the User pursuant to a separate agreement with the Administrator;</li>
                    <li>2.2.10. Reproduce, duplicate, copy, sell, carry out trade transactions and resell access to using the Resource for any purposes, except when such actions were directly permitted to the User pursuant to a separate agreement with the Administrator;</li>
                    <li>2.2.11. Post any other information, which is undesirable, disagrees with the purposes of creation of the Resource, encroaches Users’ interests or otherwise presents itself as undesirable for being posted to the Resource;</li>
                  </ul>
                  <p className="mt-2">
                    2.3. Each User shall warrant and acknowledge that:
                  </p>
                  <ul className="list-disc list-inside pl-6 space-y-2">
                    <li>2.3.1. He/she undertakes full responsibility for obtaining all necessary permits in relation to any User Content, which he/she represents, downloads, or displays;</li>
                    <li>2.3.2. Any User content represented, downloaded or displayed by him/her does not infringe any copyrights, patents, rights for trademarks, intellectual property rights or other rights of any third parties;</li>
                    <li>2.3.3. Any User Content represented, downloaded or displayed by him/her is not defamatory, libelous or obscene;</li>
                    <li>2.3.4. Any User Content represented, downloaded or displayed by him/her complies with the laws of the state where the User is located, the laws of the United Kingdom, and these Terms and Conditions;</li>
                    <li>2.3.5. The User shall not interfere with the Resource operation.</li>
                  </ul>
                </li>
                <li>
                  <strong>Items and services descriptions</strong>
                  <p className="mt-2">
                    3.1. The Resource is a marketplace for Users to post, buy, and sell goods and services. The User shall provide accurate and comprehensive information about goods and services in their announcements.
                  </p>
                  <p className="mt-2">
                    3.2. Users are responsible for ensuring that any descriptions and information about their goods and services are accurate and comply with applicable laws and regulations.
                  </p>
                  <p className="mt-2">
                    3.3. If a User finds incorrect information in their announcement, they must update the information. If updating is not possible, the announcement should be removed and reposted with correct details.
                  </p>
                  <p className="mt-2">
                    3.4. Delivery conditions should be included in the goods description, and service terms and conditions should be part of the service description. These terms must not conflict with these Terms and Conditions or applicable legislation.
                  </p>
                  <p className="mt-2">
                    3.5. The price of goods or services must be exact. If there is a change in price due to specific circumstances, the terms and conditions of the price change shall be provided in an announcement.
                  </p>
                  <p className="mt-2">
                    3.6. Delivery conditions should be  included in a goods description, and services terms and conditions should form  a part of the service description. Goods sale and service terms and conditions  developed by the User shall not interfere with these Terms and Conditions and  the applicable legislation of states, for which they are sold.
                  </p>
                  <p className="mt-2">
                    3.7. Price of goods or services shall  be exact. If it is perceived to be changed due to any specific circumstances,  the terms and conditions of price change shall be provided in an announcement.
                  </p>
                  <p className="mt-2">
                    3.8. The User is not allowed to post  or distribute:
                  </p>
                  <ul className="list-disc pl-6">
                    <li>False, misleading or deceitful information;</li>
                    <li>Disreputable, defamatory, threatening or harassing, improper, unacceptable information;</li>
                    <li>Discriminative information, or information that facilitates discrimination on the basis of race, sex, religion, nationality, invalidity, sexual orientation or age;</li>
                    <li>Information which violates these Rules;</li>
                    <li>Information which violates applicable laws and regulations (including, without limitation, those regulating export control, consumer protection, unfair competition or false advertising, intellectual property rights);</li>
                    <li>Direct or indirect references to any other web sites, which comprise any content being able to violate these Rules;</li>
                    <li>Vulgar abusive language;</li>
                    <li>Advocacy of hate, violence, discrimination, racism, xenophobia, ethnic conflicts;</li>
                    <li>Appeals to violence and unlawful actions;</li>
                    <li>Data infringing personal (non-proprietary) rights and intellectual property rights of the third parties;</li>
                    <li>Information that facilitates fraud, deception or breach of faith;</li>
                    <li>Information leading to transactions with stolen or counterfeit objects;</li>
                    <li>Information violating or encroaching on the third party’s property, commercial secret or right to privacy;</li>
                    <li>Personal or identifying information about other persons without their express consent;</li>
                    <li>Information comprising data which may encroach on privacy right, abuse anybody’s honour, merit or business reputation;</li>
                    <li>Information comprising slander or threats directed against whosoever;</li>
                    <li>Information of pornographic nature;</li>
                    <li>Information which may inflict damage to under-ages;</li>
                    <li>Information leading to transactions with any equipment which may hinder network interoperability;</li>
                    <li>False or misleading information;</li>
                    <li>Viruses or any other techniques being able to damage the Resource, Administrator or other Users;</li>
                    <li>Information about services deemed to be immoral, such as prostitution or other forms contradicting moral or legal norms;</li>
                    <li>References or information about websites competing with the Resource services;</li>
                    <li>Information representing “spam”, “chain letters”, “pyramids schemes” or undesirable or deceitful commercial advertising;</li>
                    <li>Information distributed by information agencies;</li>
                    <li>Information offering to earn over the Internet without employer’s actual address or direct contacts indicated;</li>
                    <li>Information of multistage and network marketing or any other activity, which requires recruiting of other members, subagents, sub-distributors, etc.;</li>
                    <li>Information of solely promotional nature with no offers of specific goods or services;</li>
                    <li>Information or announcements about counterfeit and imitated goods or unauthorized copies. Unauthorized copies include also goods having been acquired by illegal means, pirated or stolen. Such goods may infringe intellectual property rights, and trademark rights as well;</li>
                  </ul>
                </li>
                <li>
                  <strong>Intellectual property rights</strong>
                  <p className="mt-2">
                    4.1. If User posts legally owned content to the Resource, he/she hereby grants to other users and the Administrator non-exclusive rights for its use solely in the scope of functionality provided by the Resource, except when such use damages or may damage legally protected right holder’s interests.
                  </p>
                  <p className="mt-2">
                    4.2. The User also grants to the Administrator a non-exclusive right to use content, which is located on the Resource and legally owned by him/her, without compensation so that the Administrator would be able to ensure operation of the Resource to the extent determined by its functionality and architecture. The abovementioned non-exclusive right is provided for the period of posting of content to the Resource covering all states over the world. The Administrator is entitled to assign rights described in this clause to the third parties.
                  </p>
                  <p className="mt-2">
                    4.3. Any use of the Resource or any content on the Resource, except that permitted by these Terms and Conditions or in the event of express right holder’s consent to such use, without the prior written consent of a right holder is strictly prohibited.
                  </p>
                  <p className="mt-2">
                    4.4. Responsibility for violation of exclusive rights. The User shall be solely responsible for any content or other information, which he/she downloads or in any other way make publicly available (posts) on the Resource, or by its means. The User shall not download, distribute or post content to the Resource if he/she is not properly entitled to such activity. In case of infringement of rights being detected, the rules of filing of notification on the infringement of rights stipulated herein shall be used.
                  </p>
                  <p className="mt-2">
                    4.5. The Administrator may, but not shall, review the Resource for a presence of any prohibited content and may delete or displace (without notice) any content at its discretion, for any reason or without it, including but not limited to deletion or displacement of content which violates these Terms and Conditions, laws and/or may infringe rights, inflict damages or endanger safety of other Users or the third parties.
                  </p>
                  <p className="mt-2">
                    4.6. Materials on the Resource, except those posted by the User, including but not limited to texts, software, scripts, graphics, photos, sounds, music, videos, interactive functions, etc. (<strong>"Materials"</strong>) and trademarks, service marks and logos included in it (<strong>"Marks"</strong>) belong to the Administrator representing items of copyright and of any other intellectual property rights. Unauthorized use of such Materials and Marks without prior notice of the Administrator is not allowed.
                  </p>
                </li>
                <li>
                  <strong>Notice for Claims of Intellectual Property Violations and Copyright Infringement</strong>
                  <p className="mt-2">
                    5.1. If you are a holder of intellectual property rights or a person authorized to act in the name of a holder of intellectual property rights and you reasonably believe that information which is posted to the Resource someway infringes your intellectual property rights or intellectual property rights of a person, in which name you act, you may provide notification to the Administrator requiring to delete such material. In this regard, you shall warrant that your appeal has a legal basis, and you act in good faith according to law.
                  </p>
                  <p className="mt-2">
                    5.2. Providing relevant notification concerning infringement of rights you shall ensure that your request corresponds to the form below and includes the following:
                  </p>
                  <ul className="list-disc pl-6">
                    <li>An appeal should include a physical or electronic signature of a person empowered for acting in the name of a holder of an exclusive right, which is believed to be infringed;</li>
                    <li>The items of intellectual property right, rights on which were supposedly infringed, shall be specified. If several items exist, the entire list of such items shall be provided;</li>
                    <li>You shall specify materials (with an indication of specific URL-pages), which are stated to infringe rights or themselves are the objects of infringement;</li>
                    <li>You shall provide contact information so that the Administrator would be able to contact you, for example, address, phone number, and email address;</li>
                    <li>Signed the application with regard to your faithful and reasonable belief in that material being the objects of complaint concerning infringement of intellectual property rights are used without appropriate authority;</li>
                    <li>Any other information relevant to the claim.</li>
                  </ul>
                  <p className="mt-2">
                    5.3. The Administrator shall consider your claim as soon as possible and notify you about results of the consideration. The Administrator is entitled to remove or block the relevant content on the Resource. If you disagree with a decision, you may submit your additional comments to the Administrator. If, in turn, you believe that materials on the Resource are used illegally or infringe any rights, please inform us about it.
                  </p>
                </li>
                <li>
                  <strong>Disclaimer and Limitation of Liability</strong>
                  <p className="mt-2">
                    6.1. The Resource and its services are provided "as is" without any warranties. The Administrator does not warrant that the Resource will be available without interruptions, errors, or without faults.
                  </p>
                  <p className="mt-2">
                    6.2. The Administrator is not responsible for any damages or losses that may occur as a result of using the Resource or any content posted by Users.
                  </p>
                  <p className="mt-2">
                    6.3. The User is responsible for any content they post and for any transactions made through the Resource. The Administrator does not guarantee the accuracy, reliability, or legality of any content or transactions.
                  </p>
                  <p className="mt-2">
                    6.4. The Administrator is not liable for any third-party content or for any issues arising from the use of third-party services.
                  </p>
                </li>
                <li>
                  <strong>Changes to Terms</strong>
                  <p className="mt-2">
                    7.1. The Administrator reserves the right to modify these Terms and Conditions at any time. Any changes will be communicated to Users through the Resource or by other means.
                  </p>
                  <p className="mt-2">
                    7.2. Users are encouraged to review these Terms and Conditions periodically for any changes. Continued use of the Resource constitutes acceptance of any modifications.
                  </p>

                </li>
                <li>
                  <strong>Disclaimer and Limitation of Liability</strong>
                  <p className="mt-2">
                    6.1. The Resource and its services are provided "as is" without any warranties. The Administrator does not warrant that the Resource will be available without interruptions, errors, or without faults.
                  </p>
                  <p className="mt-2">
                    6.2. The Administrator is not responsible for any damages or losses that may occur as a result of using the Resource or any content posted by Users.
                  </p>
                  <p className="mt-2">
                    6.3. The User is responsible for any content they post and for any transactions made through the Resource. The Administrator does not guarantee the accuracy, reliability, or legality of any content or transactions.
                  </p>
                  <p className="mt-2">
                    6.4. The Administrator is not liable for any third-party content or for any issues arising from the use of third-party services.
                  </p>
                </li>
                <li>
                  <strong>Changes to Terms</strong>
                  <p className="mt-2">
                    7.1. The Administrator reserves the right to modify these Terms and Conditions at any time. Any changes will be communicated to Users through the Resource or by other means.
                  </p>
                  <p className="mt-2">
                    7.2. Users are encouraged to review these Terms and Conditions periodically for any changes. Continued use of the Resource constitutes acceptance of any modifications.
                  </p>
                  <p className="mt-2">
                    7.3. Any data downloaded or acquired in any other way by means of the Resource are created at each User’s discretion, and each User shall be fully responsible for any damages inflicted to the computer system or loss of data, which may arise as a result of downloading any such data.
                  </p>
                  <p className="mt-2">
                    7.4. The Administrator and affiliated parties shall bear no responsibility for materials posted by the Users, as well as for goods and services offered by the User for trade. The Administrator disclaims all warranties regarding that quality of goods and services acquired by means of the Resource will be consistent with a buyer’s expectations and/or demands. The Administrator makes no warranties about that goods, services, or information ordered by means of the Resource will be provided by the Resource User in accordance with a buyer’s expectations.
                  </p>
                </li>
                <li>
                  <strong>Indemnity</strong>
                  <p className="mt-2">
                    8.1. Each User agrees to be obliged to indemnify the Administrator, affiliated parties, directors, officials, and employees from any and all losses, claims, liabilities (as well as from legal costs to the fullest extent), which may arise following Users’ use of the Resource, as a result of violation of any condition of the Terms and Conditions, or violation of representations and warranties made by him/her towards the Administrator.
                  </p>
                  <p className="mt-2">
                    8.2. Each User hereby agrees to indemnify the Administrator, affiliated parties, directors, officials, and employees from any and all losses, claims, liabilities, which may arise, whether directly or indirectly, as a result of any claims made by holders/claimants of the Third parties or other parties’ rights, related to the goods and services offered or displayed on the Resource. Each User hereby acknowledges that the Administrator shall have no liabilities or responsibilities against you with regard to any data posted by any other persons, as well as those discreditable or illegal, and risk of losses related to such data remains entirely with each User.
                  </p>
                  <p className="mt-2">
                    8.3. The Administrator shall not be responsible for any expressed or implicit, penalty, accidental, or consequential losses or damage of whatsoever nature (including, but not limited to, losses related to loss of profit or saving, termination of business, loss of information, loss of benefit), incurred as a result of transactions, negligence, delinquency, or in any other way, or any other losses related to the following actions:
                  </p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Use or impossibility of use of the Resource;</li>
                    <li>In case of any defects of goods, patterns, data, information or services purchased or otherwise acquired from the User or by any other party by means of the Resource;</li>
                    <li>Infringement of the third-party's rights or claims, or requirements for production, import, export, distribution, offer, display, acquisition, sale, and/or use of User’s products or services, offered or displayed on the Resource, which may infringe or may be purported as infringing the third parties’ rights; or a claim of any party related to rights protection;</li>
                    <li>Unauthorized third parties’ access to any User’s data or personal information;</li>
                    <li>Applications or actions of any Resource User; or</li>
                    <li>Other actions related to the use of Resource and arising by negligence, as well.</li>
                  </ul>
                </li>
                <li>
                  <strong>Enforcement</strong>
                  <p className="mt-2">
                    9.1. The Administrator reserves the right to delete or block access to information posted by User without notice in the event of:
                  </p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Receiving mandatory judgments of competent public authorities;</li>
                    <li>Claim of a holder of intellectual property rights to terminate infringement of his/her rights by a user on the Resource; other infringements of rights or legal interests of other Resource Users, of legal entities or individuals upon their reasonable appeal;</li>
                    <li>Detecting information, which posting to the Resource is prohibited under these Rules.</li>
                  </ul>
                  <p className="mt-2">
                    9.2. The Administrator shall be entitled to block access to the information posted by users to the Resource at its sole discretion having provided a user with relevant substantiation.
                  </p>
                </li>
                <li>
                  <strong>Users and Organizations Interaction</strong>
                  <p className="mt-2">
                    10.1. The Administrator disclaims any responsibility for User’s interaction with any organizations and/or persons in the course of using of the Resource. This includes, but is not limited to, payments for and delivery of goods and services, as well as any other interaction in relation to other organizations and/or individuals. Transactions are concluded only between Users and such organizations and/or individuals. The Administrator disclaims responsibility for such interactions or other losses incurred following such relations or interactions. If any dispute arises between you and one or several other users, you shall indemnify the Administrator, its officials, employees, agents, and successors from any and all claims, requirements, and losses (whether direct or indirect) of whatsoever kind or nature, which arise or relate to such disputes and/or goods and services.
                  </p>
                </li>
                <li>
                  <strong>Mandatory Arbitration</strong>
                  <p className="mt-2">
                    11.1. Please read this Section carefully. YOU AND ADMINISTRATOR, AND EACH OF OUR RESPECTIVE AGENTS, CORPORATE PARENTS, SUBSIDIARIES, AFFILIATES, PREDECESSORS IN INTEREST, SUCCESSORS AND ASSIGNS, AGREE TO ARBITRATION (EXCEPT FOR MATTERS THAT MAY BE TAKEN TO SMALL CLAIMS COURT), AS THE EXCLUSIVE FORM OF DISPUTE RESOLUTION EXCEPT AS PROVIDED FOR BELOW, FOR ALL DISPUTES AND CLAIMS ARISING OUT OF OR RELATING TO THIS TERMS AND CONDITIONS OR THE SERVICE, UNLESS YOU ARE LOCATED IN A JURISDICTION THAT PROHIBITS THE EXCLUSIVE USE OF ARBITRATION FOR DISPUTE RESOLUTION. Arbitration is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, allows for more limited discovery than in court, and is subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award. Please visit www.adr.org for more information about arbitration.
                  </p>
                  <p className="mt-2">
                    11.2. Commencing Arbitration. A party intending to seek arbitration must first send to the other, by an international courier with a tracking mechanism, a written notice of intent to arbitrate (a “Notice”), or, in the absence of a mailing address provided by you to Administrator, via any other method available to Administrator, including via e-mail. The Notice to Administrator must be addressed to VENSLE LTD. (via email to contact@vensle.com), Attn: Chief Executive Officer (the "Arbitration Notice Address"). The Notice must
                  </p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Describe the nature and basis of the claim or dispute; and</li>
                    <li>Set forth the specific relief sought (the "Demand"). If you and Administrator do not reach an agreement to resolve the claim within 30 days after the Notice is received, you or Administrator may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by you or Administrator shall not be disclosed to the arbitrator until after the arbitrator makes a final decision and award if any.</li>
                  </ul>
                  <p className="mt-2">
                    11.3. Selection of Arbitrator. The arbitration will be conducted before a single arbitrator. The arbitration will be conducted in the English language in [Your City, State or Jurisdiction]. The arbitration will be governed by the [Name of Arbitration Association] and the arbitration rules that are in effect at the time the Notice is sent. You and Administrator shall have the right to conduct discovery to the extent provided by the rules of the arbitration forum. Any arbitration award will be final and binding on all parties, and judgment may be entered thereon in any court of competent jurisdiction.
                  </p>
                </li>
                <li>
                  <strong>Miscellaneous</strong>
                  <p className="mt-2">
                    12.1. These Terms and Conditions constitute the entire agreement between you and Administrator concerning your use of the Resource, superseding any prior agreements between you and Administrator relating to your use of the Resource.
                  </p>
                  <p className="mt-2">
                    12.2. The failure of Administrator to enforce any right or provision of these Terms and Conditions will not be deemed a waiver of such right or provision. If any provision of these Terms and Conditions is held to be invalid or unenforceable, such provision will be deemed modified to the extent necessary to make it valid and enforceable, and the remaining provisions will remain in full force and effect.
                  </p>
                  <p className="mt-2">
                    12.3. These Terms and Conditions are governed by the laws of [Your Jurisdiction], without regard to its conflict of law principles.
                  </p>
                  <p className="mt-2">
                    12.4. The Resource is not directed to individuals under the age of 18. By using the Resource, you represent and warrant that you are 18 years of age or older.
                  </p>
                </li>
              </ol>
            </div>
          </div>




        </div>
      </main>

      <Footer />
    </div>
  );
};

export default TermsOfUse;

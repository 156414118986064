import React from 'react';
import ChatComponent from './ChatComponent';

const Chat = () => {
	return (
		// <ChatComponent />
		<p>Test chat</p>
	)
}

export default Chat

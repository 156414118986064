import Card from "components/card";
import BarChart from "components/charts/BarChart";
import {
  barChartDataWeeklyRevenue,
  barChartOptionsWeeklyRevenue,
} from "variables/charts";
import { MdBarChart } from "react-icons/md";

const WeeklyRevenue = ({ title = "Weekly Revenue" }) => {
  return (
    <Card extra="flex flex-col bg-white w-full rounded-3xl py-6 px-2 text-center">
      <div className="mb-auto flex items-center justify-between px-6">
        <h3 className="text-xl text-navy-700 font-bold">Profiles</h3>
        <div className="flex jusity-between items-center">
          <h2 className="dark:text-white mr-4 text-[14px]">
            {/* {title} */}
            <span className="bg-[#2b3966] inline-flex rounded-full h-2 w-2"></span>{" "}
            <span className="text-[#2b3966]">Users</span>{" "}
            <span className="ml-3 bg-[#ff7272] inline-flex rounded-full h-2 w-2"></span>{" "}
            <span className="text-[#ff7272]">Products</span>
          </h2>
          <select className="bg-white py-1 px-3 text-xs text-gray-400 border border-blue-300 rounded-md">
            <option>Yearly</option>
          </select>
        </div>

        {/* <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
          <MdBarChart className="h-6 w-6" />
        </button> */}
      </div>

      <div className="md:mt-16 lg:mt-0">
        <div className="h-[250px] w-full xl:h-[350px]">
          <BarChart
            chartData={barChartDataWeeklyRevenue}
            chartOptions={barChartOptionsWeeklyRevenue}
          />
        </div>
      </div>

      <div className="text-center text-sm mt-1 color-navy-700">
        <p>2023 - 2024</p>
      </div>
    </Card>
  );
};

export default WeeklyRevenue;

import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/20/solid";

import { SET_MESSAGE } from "actions/types";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const imageBaseURL = process.env.REACT_APP_IMAGE_BASE_URL;

export default function CompleteOrder({ open, setOpen, delivery_code, receiver_id, items, orderDetails }) {
  const dispatch = useDispatch();
  
  const accessToken = useSelector((state) => state.auth?.user?.token);
  const user = useSelector((state) => state.auth?.user?.user);
  const isAuthenticated = useSelector((state) => state.auth?.isLoggedIn);

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [feedback, setFeedback] = useState(
    items.map((item) => ({ vendor_id: item.buyer_id, rating: "", feedback: "" }))
  );

  const handleInputChange = (index, field, value) => {
    const updatedFeedback = [...feedback];
    updatedFeedback[index][field] = value;
    setFeedback(updatedFeedback);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(null);
    const data = {
      delivery_code,
      receiver_id,
      message,
      driver_feedback: feedback,
      delivery_code: orderDetails.delivery_code,
    };
    // console.log(data)
    try {
      const response = await axios.post(`${apiBaseURL}/orders/${orderDetails.id}/complete`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          type: "success",
          message: "Simulation: Order item successfully completed",
        },
      });
      window.location.reload();
    } catch (error) {
      console.log('me err', error.response.data.error)
      if (error.response && error.response.data && error.response.data.error) {
        setErrors(error.response.data.error); // Store the errors in state
      } else {
        setErrors({ general: "An error occurred while submitting the form." });
      }
    }
  };

  const getImagePath = (name) => {
    return `${imageBaseURL}/${name}`;
  };



  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          {/* {console.log('deliver me', delivery_code)}
          {console.log('receive me', receiver_id)}
          {console.log('feedme', feedback)}
          {console.log('msg', message)} */}
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative rounded-lg flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  {console.log(errors)}
                  <div className="w-full">
                    {/* {errors && Object.keys(errors).length > 0 && (
                      <div style={{ color: "red" }}>
                        {Object.entries(errors).map(([field, messages]) => (
                          <div key={field}>
                            {messages.map((msg, idx) => (
                              <p key={idx}>{msg}</p>
                            ))}
                          </div>
                        ))}
                      </div>
                    )} */}
                    <form onSubmit={handleSubmit}>
                      <div>
                        <label>Message:</label>
                        <input
                          type="text"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          required
                          className="mt-1 block w-full rounded-lg border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-blue-500"
                        />
                      </div>

                      {items.map((item, index) => (
                        <div className="my-5 border rounded-md p-4" key={item.id} style={{ marginBottom: "20px" }}>
                          <h3 className={`mt-[-28px] px-2 table rounded-md ${item.status === 'Completed' ? 'bg-blue-100' : 'bg-red-200'
                            }`}>Product {item.name}</h3>
                          <input
                            type="hidden"
                            value={feedback[index].vendor_id}
                            required
                          />
                          <div className="flex items-center my-2">
                            <label className="w-[15%]">Rating:</label>
                            <input
                              type="number"
                              min="1"
                              max="5"
                              value={feedback[index].rating}
                              onChange={(e) => handleInputChange(index, "rating", e.target.value)}
                              className="ml-3 w-15 rounded-lg border-gray-300 bg-gray-50 py-1 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-blue-500"
                              required
                            />
                          </div>
                          <div className="flex items-center">
                            <label className="w-[15%]">Feedback:</label>
                            <input
                              type="text"
                              value={feedback[index].feedback}
                              onChange={(e) => handleInputChange(index, "feedback", e.target.value)}
                              className="ml-3 block flex-1 rounded-lg border-gray-300 bg-gray-50 py-3 px-4 text-sm placeholder-gray-300 shadow-sm outline-none transition focus:ring-2 focus:ring-blue-500"
                              required
                            />
                          </div>
                        </div>
                      ))}

                      <div className="flex gap-4">
                        <p>Delivery code: {orderDetails.delivery_code}</p>
                      </div>
                      <button className="linear flex items-center mt-3 px-12 rounded-xl bg-primaryColor py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-400 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200" type="submit">Complete</button>
                    </form>

                  </div>


                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import React, { useState } from "react";
import { useTable, useRowSelect, usePagination, useSortBy, useFilters, useGlobalFilter } from "react-table";

const Table = ({ columns, data, loading, handleProductQuickView }) => {

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		canNextPage,
		canPreviousPage,
		previousPage,
		pageOptions,
		gotoPage,
		pageCount,
		selectedFlatRows,
		prepareRow,
		state,
		setGlobalFilter,
	} = useTable(
		{
			columns, data, initialState: {
				globalFilter: ""
			}
		},
		useFilters, //For column-specific filter
		useGlobalFilter, //For global filter
		useSortBy,
		usePagination,
		useRowSelect,
	)

	const { globalFilter, pageIndex } = state;

	return (
		<div className="">
			<input
				type="text"
				className="px-3 w-full lg:w-[30%] bg-white lg:absolute top-[28px] border border-gray-200 right-[1rem] rounded-md py-2 pl-6 pr-2 mb-6"
				value={globalFilter}
				onChange={(e) => setGlobalFilter(e.target.value)}
				placeholder="Search"
			/>

			<div className="min-h-[50vh]">
				<table
					cellpadding="10"
					className="w-full rounded-md"
					{...getTableProps()}
				>
					{loading &&
						<div className="absolute bg-white/50 top-0 flex inset-0 items-center justify-center z-[1] w-full">
							Loading
						</div>
					}
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr
								className="bg-white border-b border-b-gray-200"
								{...headerGroup.getHeaderGroupProps()}
							>
								{headerGroup.headers.map((column) => (
									<th className="py-3 text-[17px] font-medium text-left" {...column.getHeaderProps(column.getSortByToggleProps())}>
										{column.render('Header')}
										<span>
											{column.isSorted
												? (column.isSortedDesc
													? 'V'
													: 'X')
												: ''}
										</span>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody className="relative" {...getTableBodyProps()}>
						{/*improve condition*/}
						{/* {page.map((row) => { */}
						{page[0]?.original?.id ? page.map((row) => {
							prepareRow(row);
							return (
								<tr
									className="hover:bg-gray-100"
									{...row.getRowProps()}
								>
									{row.cells.map((cell) => {
										return (
											<td
												className="py-2 cursor-pointer text-[14px]" {...cell.getCellProps()}
												onClick={(e) => handleProductQuickView(e, cell.row.original)}

											>
												{cell.render('Cell')}
											</td>
										);
									})}
								</tr>
							);
						}) : (loading ?
							<div className="top-0 mt-4 ml-4 w-full">
								Loading...
							</div>: <div className="top-0 mt-4 ml-4 w-full">
								 You have no Products
							</div>)
						}
					</tbody>
				</table>
			</div>

			{page[0]?.original?.id && <div className="text-center mt-8 mb-4">
				<span className="mr-6">
					Page{' '}
					<strong>
						{pageIndex + 1} of {pageOptions.length}
					</strong>
				</span>
				<span>
					<button
						className="border mr-2 border-gray-300 p-2 rounded-md"
						onClick={() => gotoPage(0)}
						disabled={!canPreviousPage}
					>
						{'<<'}
					</button>
					<button
						className="border mr-2 border-gray-300 p-2 rounded-md"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
					>
						{'<'}
					</button>
					<button
						className="border mr-2 border-gray-300 p-2 rounded-md"
						onClick={() => nextPage()}
						disabled={!canNextPage}
					>
						{'>'}
					</button>
					<button
						className="border border-gray-300 p-2 rounded-md"
						onClick={() => gotoPage(pageCount - 1)}
						disabled={!canNextPage}
					>
						{'>>'}
					</button>
				</span>
				<span className="ml-5">
					Go to page: {' '}
					<input
						type='number'
						className="w-10 ml-3 py-1 px-2 border border-gray-300 rounded-md"
						defaultValue={pageIndex + 1}
						onChange={e => {
							const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
							gotoPage(pageNumber)
						}}
					/>
				</span>
			</div>}
		</div>
	)
}

export default Table
